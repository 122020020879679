
import { Tooltip } from "@chakra-ui/react";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { CellProps, Column } from "react-table";
import tw, { css } from "twin.macro";
import {
    GrossProfitReportEstimateEntry,
    GrossProfitSummaryQuery
} from "../../codegen/graphql-types";
import { currencyFormatter } from "./Charts/constants";

export const useNetSalesReportData = ({
  entries,
  endYear,
  startYear,
  idToPersonMap,
}: {
  entries: Array<any>;
  endYear: number;
  startYear: number;
  idToPersonMap: Record<string, any>;
}) => {
  const { postProcessedRows, columnDefinitions, ownerIds } = useMemo(() => {
    const monthNumbers = _.range(12).map((i) => i + 1);
    const ownerIdSet = new Set<string>();

    const entriesByYear = entries.reduce<
      Record<
        string,
        Record<
          number,
          {
            gross_profit: Record<"total" | string, number>;
            entriesByOwner: Record<
              string,
              Array<GrossProfitReportEstimateEntry>
            >;
            entries: Array<
              NonNullable<
                GrossProfitSummaryQuery["grossProfitReport"]
              >["entries"][number] & {
                owner: typeof idToPersonMap[keyof typeof idToPersonMap];
              }
            >;
          }
        >
      >
    >(
      (acc, entry) => {
        ownerIdSet.add(entry.owner_id);
        const entryDate = DateTime.fromISO(entry.estimate_date, {
          zone: "utc",
        });
        return {
          ...acc,
          [entryDate.year]: {
            ...acc[entryDate.year],
            [entryDate.month]: {
              gross_profit: {
                ...acc[entryDate.year]?.[entryDate.month]?.gross_profit,
                total:
                  (acc[entryDate.year]?.[entryDate.month]?.gross_profit.total ??
                    0) +
                  (entry.estimate_po_subtotal ?? 0) -
                  (entry.expense_amount ?? 0),
                [entry.owner_id]: _.sum([
                  acc[entryDate.year]?.[entryDate.month]?.gross_profit[
                    entry.owner_id
                  ],
                  entry.estimate_po_subtotal,
                  -entry.expense_amount,
                ]),
              },
              entriesByOwner: {
                ...acc[entryDate.year]?.[entryDate.month]?.entriesByOwner,
                [entry.owner_id]: [
                  ...(acc[entryDate.year]?.[entryDate.month]?.entriesByOwner?.[
                    entry.owner_id
                  ] ?? []),
                  entry,
                ],
              },
              entries: (
                acc[entryDate.year]?.[entryDate.month]?.entries ?? []
              ).concat({
                ...entry,
                owner: idToPersonMap[entry.owner_id],
              }),
            },
          },
        };
      },
      {
        [startYear]: {},
        [endYear]: {},
      }
    );

    const entriesInStartYear = entriesByYear?.[startYear] ?? [];
    const entriesInEndYear = entriesByYear?.[endYear] ?? [];

    const postProcessedRows = monthNumbers.map((monthNumber, i) => {
      const startYearCumulativeGrossProfit = _.sumBy(
        monthNumbers.slice(0, i + 1),
        (monthNumber) =>
          entriesInStartYear[monthNumber]?.gross_profit.total ?? 0
      );

      const endYearCumulativeGrossProfit = _.sumBy(
        monthNumbers.slice(0, i + 1),
        (monthNumber) => entriesInEndYear[monthNumber]?.gross_profit.total ?? 0
      );

      return {
        monthNumber,
        monthName: DateTime.now().set({
          month: monthNumber,
        }).monthLong,
        startYearGrossProfit:
          entriesInStartYear[monthNumber]?.gross_profit.total ?? 0,
        endYearGrossProfit:
          entriesInEndYear[monthNumber]?.gross_profit.total ?? 0,

        startYearCumulativeGrossProfit,
        endYearCumulativeGrossProfit,

        startYearGrossProfitDict:
          entriesInStartYear[monthNumber]?.gross_profit ?? {},
        endYearGrossProfitDict:
          entriesInEndYear[monthNumber]?.gross_profit ?? {},

        startYearEntriesByOwnerDict:
          entriesInStartYear[monthNumber]?.entriesByOwner,
        endYearEntriesByOwnerDict:
          entriesInEndYear[monthNumber]?.entriesByOwner,
      };
    });

    const ownerIds = Array.from(ownerIdSet);

    const trulyPostProcessedRows = postProcessedRows.reduce<
      Array<
        typeof postProcessedRows[number] & {
          endYearCumulativeGrossProfitByOwnerDict: Record<string, number>;
          startYearCumulativeGrossProfitByOwnerDict: Record<string, number>;
        }
      >
    >((processedRows, row, i, arr) => {
      // these rows are already ordered by month (jan > december)
      // const prevRow = arr[i - 1];
      const prevRow = processedRows.slice(-1)[0];
      return processedRows.concat({
        ...row,
        endYearCumulativeGrossProfitByOwnerDict: Object.fromEntries(
          ownerIds.map((ownerId) => {
            return [
              ownerId,
              (prevRow?.endYearCumulativeGrossProfitByOwnerDict?.[ownerId] ??
                0) + (row.endYearGrossProfitDict[ownerId] ?? 0),
            ];
          })
        ),
        startYearCumulativeGrossProfitByOwnerDict: Object.fromEntries(
          ownerIds.map((ownerId) => {
            return [
              ownerId,
              (prevRow?.startYearCumulativeGrossProfitByOwnerDict?.[ownerId] ??
                0) + (row.startYearGrossProfitDict[ownerId] ?? 0),
            ];
          })
        ),
      });
    }, []);

    const lastRow = _.last(trulyPostProcessedRows);

    const ownerIdsOrderedByCumulativeProfit = _.orderBy(
      Array.from(ownerIdSet).filter(ownerId => lastRow?.endYearCumulativeGrossProfitByOwnerDict[ownerId] ?? 0 > 0),
      (ownerId) => {
        return (
          lastRow
            ?.endYearCumulativeGrossProfitByOwnerDict[ownerId] ?? 0
        );
      },
      "desc"
    );

    type Row = typeof postProcessedRows[number];
    type ColDefinition = Column<Row> & {
      showWhen: "cumulative" | "not_cumulative" | "always";
    };

    const columnDefinitions: Array<ColDefinition> = [
      {
        id: "monthNumber",
        Header: "",
        showWhen: "always",
        accessor: (x) => x.monthNumber,
        Cell: ({ cell, row }: CellProps<Row>) => row.original.monthName,
      },
      {
        id: "startYearGrossProfit",
        Header: startYear,
        showWhen: "not_cumulative",
        accessor: (x) => x.startYearGrossProfit,
        Cell: ({ cell, row }: CellProps<Row>) =>
          `$${currencyFormatter.format(cell.value)}`,
      },
      {
        id: "startYearCumulativeGrossProfit",
        showWhen: "cumulative",
        Header: <>{startYear}</>,
        accessor: (x) => x.startYearCumulativeGrossProfit,
        Cell: ({ cell, row }: CellProps<Row>) =>
          `$${currencyFormatter.format(cell.value)}`,
      },
      {
        id: "endYearGrossProfit",
        showWhen: "not_cumulative",
        Header: endYear,
        accessor: (x) => x.endYearGrossProfit,
        Cell: ({ cell, row }: CellProps<Row>) =>
          `$${currencyFormatter.format(cell.value)}`,
      },
      {
        id: "endYearCumulativeGrossProfit",
        showWhen: "cumulative",
        Header: <>{endYear}</>,
        accessor: (x) => x.endYearCumulativeGrossProfit,
        Cell: ({ cell, row }: CellProps<Row>) =>
          `$${currencyFormatter.format(cell.value)}`,
      },
      ...ownerIdsOrderedByCumulativeProfit.flatMap((ownerId) => {
        const owner = idToPersonMap[ownerId];
        const column: Array<ColDefinition> = [
          {
            id: `owner_${ownerId}`,
            showWhen: "not_cumulative",
            Header: (
              <div
                css={tw`cursor-pointer`}
                onClick={() => {
                  // console.log("owner");
                  // @ts-expect-error
                  setOwnerInFocus(owner);
                }}
              >
                {owner?.first_name} {owner?.last_name.substr(0, 1)}.{" "}
                <small>({endYear})</small>
              </div>
            ),
            accessor: (x) => x.endYearGrossProfitDict[ownerId] ?? 0,
            Cell: ({ cell, row }: CellProps<Row>) => (
              <Tooltip
                maxWidth={"none"}
                label={
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>PO Subtotal</th>
                          <th>COGS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.original.endYearEntriesByOwnerDict?.[ownerId]?.map(
                          (entry) => (
                            <tr
                              css={css`
                                & > td {
                                  padding-left: 0.5rem;
                                  padding-right: 0.5rem;
                                }
                                &:nth-child(even) {
                                  background-color: rgba(255, 255, 255, 0.1);
                                }
                              `}
                            >
                              <td>
                                <div style={{ width: "max-content" }}>
                                  {entry.estimate_name}
                                </div>
                              </td>
                              <td align="right">
                                $
                                {currencyFormatter.format(
                                  entry.estimate_po_subtotal
                                )}
                              </td>
                              <td align="right">
                                $
                                {currencyFormatter.format(entry.expense_amount)}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                }
              >
                <span>${currencyFormatter.format(cell.value)}</span>
              </Tooltip>
            ),
          },
          {
            id: `owner_${ownerId}_cumulative`,
            showWhen: "cumulative",
            Header: (
              <div
                css={tw`cursor-pointer`}
                onClick={() => {
                  // console.log("owner");
                  // @ts-expect-error
                  setOwnerInFocus(owner);
                }}
              >
                {owner?.first_name} {owner?.last_name.substr(0, 1)}.{" "}
                <small>({endYear})</small>
              </div>
            ),
            accessor: (x) => x.endYearGrossProfitDict[ownerId] ?? 0,
            // Cell: ({ cell, row, rows }: CellProps<Row>) => {
            //   //   const prevRowValue = rows[row.index - 1]?.original.endYearGrossProfitDict[ownerId] ?? 0;
            //   return `$${currencyFormatter.format(val)}`;
            // },
            Cell: ({ cell, row, rows }: CellProps<Row>) => {
              const val = _.sumBy(
                rows.slice(0, row.index + 1),
                (r) => r.original.endYearGrossProfitDict[ownerId] ?? 0
              );

              return `${currencyFormatter.format(val)}`;
            },
          },
        ];
        return column;
      }),
    ];

    // console.log({
    //   postProcessedRows: postProcessedRows,
    //   trulyPostProcessedRows,
    // });

    return {
      postProcessedRows: trulyPostProcessedRows,
      columnDefinitions,
      ownerIds: ownerIdsOrderedByCumulativeProfit,
    };
  }, [entries, endYear, idToPersonMap, startYear]);
  return {
    postProcessedRows,
    columnDefinitions,
    ownerIds,
  };
};
