import tw from "twin.macro";
import { useEffect } from "react";
import { RuntimeConfig } from "../RuntimeConfig";

const LoginWithQuickbooks = ({ setIsAuthenticated }: {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}) => {
  const redirectRoute = RuntimeConfig.backendOrigin + "/quickbooks/login";

  useEffect(() => {
    const loginCallback = async () => {
      // get the code from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const state = urlParams.get("state");
      const realmId = urlParams.get("realmId");

      if (!code) return;

      // Send the code to the backend with the other params
      const response = await fetch(`${RuntimeConfig.backendOrigin}/quickbooks/callback?code=${code}&state=${state}&realmId=${realmId}`, {
        credentials: 'include', // Important for setting cookies in browser
      });

      // console.log("loginCallback response", response);
      
      if (response.ok) {
        const data = await response.json();
        
        // console.log("loginCallback data", data);
        
        // Store the data in local storage
        localStorage.setItem("qbJWT", JSON.stringify(data));
        
        // Clear the "code" parameter from the URL
        window.history.replaceState({}, document.title, "/financials/invoices");

        setIsAuthenticated(true);
      }
    };

    // if there is no record of a successful login attempt in localStorage then call the loginCallback
    if (!localStorage.getItem("qbJWT")) {
      loginCallback();
    }
  }, []);

  const loginWithRedirect = async () => {
    try {
      const response = await fetch(redirectRoute, {
        method: "GET",
        credentials: 'include', // Important for handling cookies if needed
        redirect: 'manual' // This prevents fetch from automatically following redirects
      });
      
      if (response.type === 'opaqueredirect') {
        // The server sent a redirect. We need to handle it manually.
        window.location.href = response.url;
      } else {
        // Handle other response types if necessary
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error("Error during login redirect:", error);
    }
  };


  return (
    <button 
      css={tw`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`}
      onClick={loginWithRedirect}>
        Log In With Quickbooks
    </button>
  );
};

export default LoginWithQuickbooks;