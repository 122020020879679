import tw, { css } from "twin.macro";
import { useMemo, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { DateTime } from "luxon";
import {
  usePeopleTimeTargetsQuery,
  useAddPersonAnnualTimeTargetsMutation,
} from "../../codegen/graphql-types";
import type { PersonAnnualTimeTarget } from "../../codegen/graphql-types";
import {
  startOfDay,
  endOfDay,
  subDays,
  // format
} from "date-fns";
import { useAuthContext } from "../../useAuth";
import { dashboardPage } from "../../routes";
import { SelectYear } from "../../components/SelectYear";
import { FullSpinner } from "../../FullSpinner";
import { TeamTimeTargets } from "./TeamTimeTargets";
import { timeDataTransformer } from "./timeDataTransformer";
import { useToast } from "@chakra-ui/react";
import { holidaysForThisYear } from "../../Time/dates";

export const AdminTimePage = () => {
  const toast = useToast();
  const { isAdmin } = useAuthContext();
  const [isRefetching, setIsRefetching] = useState(false); // for refetching

  const currentYear = useMemo(() => DateTime.now().year, []);
  const [yearInFocus, setYearInFocus] = useState(currentYear);

  const yesterday = useMemo(() => {
    // Use startOfDay to ignore the time part and only focus on the date
    const today = startOfDay(new Date());
    const yesterdayDate = subDays(today, 1);
    return yesterdayDate;
  }, []);

  // start and end dates for the query for time entries for the year in focus
  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: startOfDay(new Date(yearInFocus, 0, 1)),
      endDate: endOfDay(new Date(yearInFocus + 1, 0, 1)),
    };
  }, [yearInFocus]);

  const endOfRangeDate: Date = useMemo(() => {
    if (yearInFocus === currentYear) {
      return endOfDay(yesterday);
    } else {
      return endDate;
    }
  }, [yearInFocus, currentYear, yesterday]);

  // start and end dates for the query for time entries for the year in focus
  const { data, error, loading, refetch } = usePeopleTimeTargetsQuery({
    variables: {
      year: yearInFocus,
      start_date: startDate,
      end_date: endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const [
    addTimeTargets,
    addTimeTargetsResult,
  ] = useAddPersonAnnualTimeTargetsMutation();

  const refetchTimeTargets = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  useEffect(() => {
    // Ensure the query refetches when the year in focus changes
    refetchTimeTargets();
  }, [yearInFocus]);

  if (loading) {
    return <FullSpinner />;
  }

  if (!isAdmin) {
    return <Navigate to={dashboardPage.getInstance()} />;
  }

  if (error) {
    return <div>There was an error fetching data. {error.message}</div>;
  }

  if (!data || !data.people || data.people.length === 0) {
    return <div>No data found for {yearInFocus}</div>;
  }

  const peopleTimeData = timeDataTransformer({
    data,
    yearInFocus,
    startDate,
    endDate: endOfRangeDate,
  });

  const filterByYearInFocus = (target: PersonAnnualTimeTarget) => {
    return target.year === yearInFocus;
  };

  const peopleThatNeedTimeTargets = data.people
    .filter((person) => !person.is_deleted_on_tw)
    .filter((person) => person.company === "Maven Medical Marketing Inc.")
    .filter(
      (person) =>
        person.annual_time_targets.filter(filterByYearInFocus).length === 0
    )
    .map((person) => ({
      id: person.id,
      first_name: person.first_name,
      last_name: person.last_name,
    }));

  if (peopleThatNeedTimeTargets.length > 0) {
    console.log("data.people: ", data.people);
    console.log("peopleThatNeedTimeTargets: ", peopleThatNeedTimeTargets);
  }

  return (
    <div css={tw`flex h-full flex-col justify-center items-start`}>
      <section css={tw`flex h-16 pt-4 justify-between items-center w-full`}>
        {peopleThatNeedTimeTargets.length > 0 && (
          <div css={tw`flex flex-col w-full`}>
            <p>
              One or more people do not have any time targets created for the
              year in focus ({yearInFocus})
            </p>
            <p>
              Click the button below to create targets for these people for{" "}
              {yearInFocus} (All targets default to 0 hours):
            </p>
            <button
              onClick={() => {
                addTimeTargets({
                  variables: {
                    personIds: peopleThatNeedTimeTargets.map(
                      (person) => person.id
                    ),
                    year: yearInFocus,
                  },
                  onError: (error) => {
                    toast({
                      title: "Error creating time targets",
                      description: error.message,
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    console.error(
                      "Error creating multiple time targets",
                      error
                    );
                  },
                  onCompleted: () => {
                    toast({
                      title: "Time targets created",
                      description:
                        "Time targets created for all people that needed them.",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                    refetchTimeTargets();
                  },
                });
              }}
              css={tw`w-1/4 text-blue-800 border rounded-md p-2 mt-4 hover:bg-blue-800 hover:text-white transition-colors duration-300 ease-in-out`}
              disabled={isRefetching || addTimeTargetsResult.loading}
            >
              {addTimeTargetsResult.loading ? "Creating..." : "Create Targets"}
            </button>
          </div>
        )}
        <SelectYear
          title="Year"
          yearInFocus={yearInFocus}
          setYearInFocus={setYearInFocus}
        />
      </section>
      {/* Table section */}
      <section
        css={[
          tw`w-full h-full`,
          css`
            max-width: 100vw;
            overflow-x: auto;
          `,
        ]}
      >
        <div css={tw`w-full px-6 flex flex-col items-start gap-y-6`}>
          {data && peopleThatNeedTimeTargets.length === 0 && (
            <TeamTimeTargets
              peopleTimeData={peopleTimeData}
              yearInFocus={yearInFocus}
            />
          )}
        </div>
        <div css={tw`p-6`}>
          <h2 css={tw`mb-2`}>
            <b>{holidaysForThisYear(yearInFocus).length}</b> Company-wide days
            off in {yearInFocus}
          </h2>
          <table
            css={css`
              td,
              th {
                padding-right: 20px;
              }
            `}
          >
            <thead>
              <tr>
                <th css={tw`text-left`}>Occassion</th>
                <th css={tw`text-left`}>Observed Date</th>
                <th css={tw`text-left`}>Day of Week</th>
              </tr>
            </thead>
            <tbody>
              {holidaysForThisYear(yearInFocus).map((holiday) => {
                return (
                  <tr key={holiday.id}>
                    <td>{holiday.nameEn}</td>
                    <td>
                      {DateTime.fromISO(holiday.observedDate).toFormat(
                        "MMM dd"
                      )}
                    </td>
                    <td>{DateTime.fromISO(holiday.date).toFormat("cccc")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
