import tw from "twin.macro";
import { useQuickbooksAuthContext } from "../useQuickbooksAuth";


const LogoutFromQuickbooks = ({ setIsAuthenticated }: {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}) => {

  const { quickbooksLogout } = useQuickbooksAuthContext();

  return (
    <button 
      css={tw`bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4`}
      onClick={quickbooksLogout}>
        Log Out From Quickbooks
    </button>
  );
};

export default LogoutFromQuickbooks;