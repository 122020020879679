/** @jsxImportSource @emotion/react */
import { useDropzone } from "react-dropzone";
import { RuntimeConfig } from "../../RuntimeConfig";
import tw from "twin.macro";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import _ from "lodash";
import { useCallback, useRef, useState } from "react";
import Select from "react-select";
import type ValueType from "react-select";
import type AsyncCreatable from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import * as Yup from "yup";
import type * as GraphQLTypes from "../../codegen/graphql-types";
// import { useAuthContext } from "../useAuth";
import { useEstimatesContext } from "../../helpers/useEstimates";
import { peopleIdsToExclude } from "../../Time/constants";

export const CreateNewEstimate = ({currentLoggedInUserId}: {currentLoggedInUserId: string}) => {
  const { refetch: refetchEstimates } = useEstimatesContext();

  const { data: peopleData } = useQuery<
    GraphQLTypes.PeopleForNewEstimateQuery
  >(gql`
    query PeopleForNewEstimate {
      people {
        id
        email_address
        first_name
        last_name
        title
        is_deleted_on_tw
      }
    }
  `);

  const { data: companiesData, refetch: refetchCompaniesData } = useQuery<
    GraphQLTypes.CompaniesForNewEstimateQuery
  >(gql`
    query CompaniesForNewEstimate {
      companies {
        id
        company_name
      }
    }
  `);

  const { data: brandsData, refetch: refetchBrandsData } = useQuery<
    GraphQLTypes.BrandsForNewEstimateQuery
  >(gql`
    query BrandsForNewEstimate {
      brands {
        id
        brand_name
        company {
          id
        }
      }
    }
  `);

  const [createEstimate] = useMutation<
    GraphQLTypes.CreateEstimateMutation,
    GraphQLTypes.CreateEstimateMutationVariables
  >(
    gql`
      mutation CreateEstimate($createOneEstimateData: estimateCreateInput!) {
        createOneEstimate(data: $createOneEstimateData) {
          id
        }
      }
    `
  );

  const [createCompany] = useMutation<
    GraphQLTypes.CreateCompanyMutation,
    GraphQLTypes.CreateCompanyMutationVariables
  >(gql`
    mutation CreateCompany($createOneCompanyData: companyCreateInput!) {
      createOneCompany(data: $createOneCompanyData) {
        id
        company_name
      }
    }
  `);

  const [createBrand] = useMutation<
    GraphQLTypes.CreateBrandMutation,
    GraphQLTypes.CreateBrandMutationVariables
  >(
    gql`
      mutation CreateBrand($createOneBrandData: brandCreateInput!) {
        createOneBrand(data: $createOneBrandData) {
          id
          brand_name
          company {
            id
          }
        }
      }
    `
  );

  const createEstimateFormValidationSchema = Yup.object({
    date: Yup.string().required(),
    owner_id: Yup.string().required(),
    po_number: Yup.string(),
    estimate_name: Yup.string().required(),
    company_id: Yup.string().required(),
    brand_id: Yup.string().required(),
    po_subtotal: Yup.number().required(),
    pdf_file: Yup.string().required('A PDF file key is required'),
  }).required();

  type CreateEstimateFormValues = Partial<
    Yup.InferType<typeof createEstimateFormValidationSchema>
  >;

  const [selectOwnerRef, selectCompanyRef, selectBrandRef] = [
    // @ts-ignore
    useRef<Select<any>>(null),
    useRef<
    // @ts-ignore
      AsyncCreatable<
        {
          value: string | undefined;
          label: string;
        },
        false
      >
    >(null),
    useRef<
    // @ts-ignore
      AsyncCreatable<
        {
          value: string | undefined;
          label: string;
        },
        false
      >
    >(null),
  ];

  const [uploadedFiles, setUploadedFiles] = useState<{
    id: string;
    name: string;
    contentType: string
  }[]>([]);
  const [pdfUploadError, setPdfUploadError] = useState<string | null>(null);

  const {
    errors,
    values,
    // touched,
    // dirty,
    resetForm,
    handleChange,
    submitCount,
    setFieldValue,
    submitForm,
    isSubmitting: formikIsSubmitting,
  } = useFormik<CreateEstimateFormValues>({
    initialValues: {
      date: new Date().toLocaleDateString("en-CA"),
      owner_id: currentLoggedInUserId,
      po_number: undefined,
      estimate_name: undefined,
      company_id: undefined,
      brand_id: undefined,
      po_subtotal: 0,
      pdf_file: undefined, // will be the s3 key
    },
    async onSubmit() {
      console.log("submitting form");
      if (!values.estimate_name) throw new Error(`estimate_name is required`);
      if (!values.pdf_file) throw new Error(`pdf of the estimate is required`);

      try {
        await createEstimate({
          variables: {
            createOneEstimateData: {
              date: values.date && new Date(values.date),
              brand: {
                connect: {
                  id: values.brand_id,
                },
              },
              company: {
                connect: {
                  id: values.company_id,
                },
              },
              name: values.estimate_name,
              owner: {
                connect: {
                  id: values.owner_id,
                },
              },
              po_number: values.po_number,
              po_subtotal: values.po_subtotal,
              pdf_file_key: values.pdf_file,
            },
          },
        });
        refetchEstimates();
        resetForm({
          values: {
            date: new Date().toLocaleDateString("en-CA"),
            owner_id: values.owner_id,
            po_number: undefined,
            estimate_name: undefined,
            company_id: undefined,
            brand_id: undefined,
            po_subtotal: 0,
            pdf_file: undefined,
          },
        });
        setUploadedFiles([]);
        // don't clear selected owner, this probably won't change
        // selectOwnerRef.current?.select.clearValue();
        // @ts-ignore
        selectCompanyRef.current?.select?.select.select.clearValue();
        // @ts-ignore
        selectBrandRef.current?.select?.select.select.clearValue();
      } catch (error) {
        console.error(error);
      }
    },
    validationSchema: createEstimateFormValidationSchema,
  });

  const loadSuggestedCompanyOptions = useCallback(
    _.debounce((inputValue, callback) => {
      callback(
        companiesData?.companies.flatMap((company) => {
          if (
            inputValue &&
            company.company_name
              .toLowerCase()
              .includes(inputValue.trim().toLowerCase()) === false
          ) {
            return [];
          }
          return {
            value: company.id,
            label: company.company_name,
          };
        })
      );
    }, 500),
    [companiesData]
  );

  const loadSuggestedBrandOptions = useCallback(
    _.debounce((inputValue, callback) => {
      callback(
        brandsData?.brands
          .filter((brand) => brand.company.id === values.company_id)
          .flatMap((brand) => {
            if (
              inputValue &&
              brand.brand_name
                .toLowerCase()
                .includes(inputValue.trim().toLowerCase()) === false
            ) {
              return [];
            }
            return {
              value: brand.id,
              label: brand.brand_name,
            };
          })
      );
    }, 500),
    [companiesData, values.company_id]
  );

  const [isCreatingCompany, setIsCreatingCompany] = useState(false);


  const uploadFiles = useCallback(async (files: File[]) => {
    setPdfUploadError(null);
    const file = files[0];
    if (!file) return;
  
    // Validate file type
    if (file.type !== 'application/pdf') {
      setPdfUploadError('Only PDF files are allowed');
      return;
    }
  
    // Validate file size
    if (file.size > 5 * 1024 * 1024) {
      setPdfUploadError('File size must be less than 5MB');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderPath', 'client-estimates');
  
    try {
      const response = await fetch(`${RuntimeConfig.backendOrigin}/s3/upload`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          "x-api-key": import.meta.env.VITE_S3_API_KEY!,
        },
        body: formData
      });
  
      if (!response.ok) {
        throw new Error(`Error uploading PDF. Status: ${response.status}`);
      }
  
      const { key, fileName } = await response.json();
      setUploadedFiles(uploadedFiles.concat({
        id: key,
        name: fileName,
        contentType: file.type
      }));
      
      // set the formik form value to the file s3 key
      setFieldValue('pdf_file', key);
    } catch (error: any) {
      console.error("Upload failed:", error);
      setPdfUploadError(`Error uploading PDF: ${error.message}`);
    }
  }, [uploadedFiles, setFieldValue]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    onDropAccepted: async (acceptedFiles) => {
      console.log({ acceptedFiles });
      uploadFiles(acceptedFiles);
    },
  });

  return (
    <Stack
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        submitForm();
      }}
      zIndex={200}
      css={tw`max-w-screen-2xl p-4 m-6 mt-0 bg-white rounded shadow`}
    >
      <Heading size="md">Input New Estimate </Heading>

      <section css={tw`grid grid-cols-1 md:grid-cols-3 gap-2`}>
        <FormControl
          id="date"
          isRequired
          isInvalid={submitCount > 0 && !!errors.date}
          css={tw`w-40`}
        >
          <FormLabel>Date</FormLabel>
          <Input type="date" value={values.date} onChange={handleChange} />
          <FormErrorMessage>{errors.date}</FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={submitCount > 0 && !!errors.owner_id}
          css={tw`w-48`}
        >
          <FormLabel>Owner</FormLabel>
          {peopleData?.people ? (
            <Select
              key="selectOwner"
              isDisabled={formikIsSubmitting}
              isMulti={false}
              closeMenuOnSelect={true}
              defaultValue={
                peopleData?.people
                  .filter(
                    (person) =>
                      person.id ===
                      values.owner_id
                  )
                  .map((person) => ({
                    value: person.id,
                    label: `${person.first_name} ${person.last_name}`,
                  }))[0]
              }
              onChange={(selectedOption) => {
                setFieldValue("owner_id", selectedOption?.value);
              }}
              options={peopleData?.people
                .filter((x) => !x.is_deleted_on_tw || !peopleIdsToExclude.includes(x.id))
                // currently filtering only accounts team members who can be estimate owners - see admin/time page for titles
                .filter((x) => x.title.toLowerCase().includes("client services") || x.title.toLowerCase().includes("maven") || x.title.toLowerCase().includes("project manager"))
                .map((person) => ({
                  value: person.id,
                  label: `${person.first_name} ${person.last_name}`,
                }))}
              ref={selectOwnerRef}
            />
          ) : (
            <Select key="loadingPlaceholder" isDisabled={true} />
          )}
          <FormErrorMessage>error: {errors.owner_id}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="po_number"
          isInvalid={submitCount > 0 && !!errors.po_number}
          css={tw`w-40`}
        >
          <FormLabel>PO #</FormLabel>
          <Input
            placeholder=""
            value={values.po_number ?? ""}
            onChange={handleChange}
            autoComplete="off"
          />
        </FormControl>

        <FormControl
          id="estimate_name"
          isRequired
          isInvalid={submitCount > 0 && !!errors.estimate_name}
        >
          <FormLabel>Estimate name</FormLabel>
          <Input
            placeholder=""
            value={values.estimate_name ?? ""}
            onChange={handleChange}
          />
          <FormErrorMessage>{errors.estimate_name}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="company_id"
          isRequired
          isInvalid={submitCount > 0 && !!errors.company_id}
        >
          <FormLabel>Company</FormLabel>
          <AsyncCreatableSelect
            ref={selectCompanyRef}
            defaultOptions={companiesData?.companies.flatMap((company) => ({
              value: company.id,
              label: company.company_name,
            }))}
            loadOptions={loadSuggestedCompanyOptions}
            onCreateOption={async (inputValue) => {
              setIsCreatingCompany(true);
              const createCompanyResponse = await createCompany({
                variables: {
                  createOneCompanyData: {
                    company_name: inputValue,
                  },
                },
              });
              await refetchCompaniesData();
              const createdCompany =
                createCompanyResponse.data?.createOneCompany;
              setFieldValue("company_id", createdCompany?.id);
              setIsCreatingCompany(false);
            }}
            isDisabled={formikIsSubmitting}
            isLoading={isCreatingCompany}
            isMulti={false}
            closeMenuOnSelect={true}
            formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
            value={
              companiesData?.companies.flatMap((company) => {
                if (company.id === values.company_id) {
                  return {
                    value: company.id,
                    label: company.company_name,
                  };
                }
                return [];
              })[0]
            }
            onChange={(selectedOption, { action }) => {
              if (action === "create-option") {
                return;
                // setFieldValue("company_id", undefined);
              } else if (action === "select-option") {
                setFieldValue("company_id", selectedOption?.value);
                setFieldValue("brand_id", undefined);
                // @ts-ignore
                selectBrandRef.current?.select?.select.select.clearValue();
              }
            }}
          />
          <FormErrorMessage>{errors.company_id}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="brand_id"
          isRequired
          isInvalid={submitCount > 0 && !!errors.brand_id}
        >
          <FormLabel>Brand</FormLabel>
          <AsyncCreatableSelect<
            { value: string | undefined; label: string },
            false
          >
            ref={selectBrandRef}
            defaultOptions={brandsData?.brands
              .filter((brand) => brand.company.id === values.company_id)
              .flatMap((brand) => ({
                value: brand.id,
                label: brand.brand_name,
              }))}
            loadOptions={loadSuggestedBrandOptions}
            onCreateOption={async (inputValue) => {
              const createBrandResponse = await createBrand({
                variables: {
                  createOneBrandData: {
                    brand_name: inputValue,
                    company: {
                      connect: {
                        id: values.company_id,
                      },
                    },
                  },
                },
                update(cache) {
                  cache.evict({
                    id: cache.identify({
                      __typename: "Company",
                      id: values.company_id,
                    }),
                    fieldName: "brands",
                  });
                },
              });
              await refetchBrandsData();
              const createdBrand = createBrandResponse.data?.createOneBrand;
              setFieldValue("brand_id", createdBrand?.id);
            }}
            isDisabled={formikIsSubmitting || !values.company_id}
            isMulti={false}
            closeMenuOnSelect={true}
            formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
            value={
              brandsData?.brands.flatMap((brand) => {
                if (brand.id === values.brand_id) {
                  return {
                    value: brand.id,
                    label: brand.brand_name,
                    // @ts-ignore
                  } as ValueType<{ value: string; label: string }, false>;
                }
                return [];
              })[0]
            }
            onChange={(selectedOption, { action }) => {
              if (action === "create-option") {
                return;
              } else if (action === "select-option") {
                setFieldValue("brand_id", selectedOption?.value);
              }
            }}
          />
          <FormErrorMessage>{errors.brand_id}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="po_subtotal"
          isRequired
          isInvalid={submitCount > 0 && !!errors.po_subtotal}
        >
          <FormLabel>PO Subtotal $</FormLabel>
          <Input value={values.po_subtotal ?? 0} onChange={handleChange} />
          <FormErrorMessage>{errors.po_subtotal}</FormErrorMessage>
        </FormControl>
      </section>

      <Stack direction="row">
        <section css={tw`w-full overflow-auto mb-4`}>
          <FormControl
            id="pdf_file"
            isRequired
            isInvalid={Boolean(pdfUploadError)}
          >
            <FormLabel>Estimate PDF</FormLabel>
            <div
              {...getRootProps({ className: "dropzone" })}
              css={tw`w-1/3 flex flex-col justify-center bg-gray-100 border border-gray-300 rounded p-2`}
            > 
              {uploadedFiles.length === 0 && (
                <>
                  {/* @ts-ignore */}
                  <input {...getInputProps()} />
                  <p css={tw`text-center opacity-50`}>
                    Drop Estimate PDF
                  </p>
                </>
              )}
              <FormErrorMessage>{pdfUploadError}</FormErrorMessage>
              <div>
                {uploadedFiles.map(file => {
                  file.name
                  return (
                    <div key={file.id} css={tw`flex items-center`}>
                      <p css={tw`text-center opacity-50`}>{file.name}</p>
                      <Button
                        css={tw`ml-auto`}
                        onClick={() =>
                          setUploadedFiles((uploadFiles) =>
                            uploadFiles.filter((x) => x.id !== file.id)
                          )
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
            <br/>
          </FormControl>
        </section>
      </Stack>
      {/* {errors && Object.values(errors).length > 0 && <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>} */}
      <Button type="submit" marginLeft="auto" css={tw`w-1/3`}>
        Add Estimate
      </Button>
    </Stack>
  );
};