
import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Portal,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { css } from "twin.macro";

export const PopoverInput = (props: {
  initialValue: string | number;
  displayText?: string | React.ReactNode;
  supressEditIcon?: boolean;
  popoverHeaderText?: string;
  type?: "string" | "number" | "date";
  onSubmit: (
    event: React.FormEvent<HTMLDivElement>,
    newValue: string,
    {
      onClose,
    }: {
      onClose: () => void;
    }
  ) => void;
}) => {
  const initialFocusRef = React.useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const popoverHeaderText = props.popoverHeaderText ?? "Enter the new value:";
  return (
    <Popover isLazy initialFocusRef={initialFocusRef}>
      {({ isOpen, onClose }) => {
        return (
          <>
            <PopoverTrigger>
              <div
                css={css`
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                `}
              >
                {props.displayText ?? props.initialValue}
                {props.supressEditIcon ? null : (
                  <EditIcon
                    className="cell-hover-visible"
                    style={{
                      ...(props.type === "number"
                        ? { order: -1, marginRight: "auto" }
                        : { marginLeft: "auto" }),
                    }}
                  />
                )}
              </div>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>{popoverHeaderText}</PopoverHeader>
                <PopoverBody>
                  <Stack
                    as="form"
                    onSubmit={async (e) => {
                      e.preventDefault();
                      setIsSubmitting(true);
                      await props.onSubmit(
                        e,
                        // @ts-expect-error
                        e.target["newValue"].value,
                        {
                          onClose,
                        }
                      );
                      setIsSubmitting(false);
                    }}
                  >
                    <Input
                      isDisabled={isSubmitting}
                      ref={initialFocusRef}
                      type={props.type ?? "string"}
                      name="newValue"
                      id="newValue"
                      defaultValue={props.initialValue}
                    />
                    <Button isDisabled={isSubmitting} type="submit">
                      {isSubmitting ? "submitting" : "submit"}
                    </Button>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        );
      }}
    </Popover>
  );
};
