/** @jsxImportSource @emotion/react */
import tw, { css } from "twin.macro";
import { useState } from 'react';
import PdfViewer from "../../Estimates/PdfViewer";
import {
  Button,
  useToast,
  Input,
  Textarea,
  Stack,
  Spinner
} from "@chakra-ui/react";
import { RuntimeConfig } from "../../../RuntimeConfig";
import * as QBO from "../../../../../server/lib/quickbooks/types";
import { useNavigate } from "react-router-dom";
import { clientInvoicesPage } from "../../../routes";

export default function SendInvoiceWithQuickbooks({
  quickbooksCustomer,
  quickbooksInvoice,
  dashboardDatabaseInvoiceId,
  dashboardInvoiceNumber,
  setIsSideDrawerOpen,
  updateOneClientInvoice
}: { 
  quickbooksCustomer: QBO.Customer,
  quickbooksInvoice: QBO.Invoice,
  dashboardDatabaseInvoiceId: string,
  dashboardInvoiceNumber: string,
  setIsSideDrawerOpen: (isOpen: boolean) => void
  updateOneClientInvoice: any
}) {
  const toast = useToast();
  const navigate = useNavigate();
  let ourCompany = 'Maven Medical Marketing Inc.';

  // console.log('quickbooksInvoice:', quickbooksInvoice);
  const invoiceAlreadySent = quickbooksInvoice.EmailStatus === 'EmailSent';
  // console.log("invoiceAlreadySent:", invoiceAlreadySent);


  const [isSending, setIsSending] = useState(false);
  const [values, setValues] = useState({
    to: quickbooksInvoice.BillEmail.Address || '',
    subject: `Invoice ${dashboardInvoiceNumber} from ${ourCompany}`,
    body: `Dear ${quickbooksCustomer.DisplayName},\n\nWe appreciate your business. Please find your invoice details here. Feel free to contact us if you have any questions.\n\nHave a great day!\n${ourCompany}`
  });
  const [errors, setErrors] = useState({
    to: '',
    subject: '',
    body: ''
  });  

  const validateEmail = (email: string) => {
    if (!email) {
      setErrors({ ...errors, to: 'Email is required' });
      return false;
    } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setErrors({ ...errors, to: 'Email is invalid' });
      return false;
    }
    return true;
  };

  const validateFields = (values: { [key: string]: string }) => {
    const subject = values.subject;
    const body = values.body;
    if (!subject) {
      setErrors({ ...errors, subject: 'Subject is required' });
      return false;
    }
    if (!body) {
      setErrors({ ...errors, body: 'Body is required' });
      return false;
    }
    return true;
  }

  const updateDatabaseDateInvoiced = async () => {
    const date = new Date();
    try {
      await updateOneClientInvoice({
        variables: {
          updateOneClientInvoiceData: {
            date_invoiced: {
              set: date
            }
          },
          updateOneClientInvoiceWhere: {
            id: dashboardDatabaseInvoiceId,
          },
        },
      });
    } catch (error) {
      throw new Error(`Error updating database date_invoiced on ${date.toISOString()} - for dashboard invoice ${dashboardInvoiceNumber}. Please do this manually on the Invoices page. Invoice id: ${dashboardDatabaseInvoiceId}`);
    }
  };


  const submitForm = async () => {
    // Validation checks
    const isEmailValid = validateEmail(values.to);
    const areFieldsValid = validateFields(values);

    if (!isEmailValid || !areFieldsValid) {
      console.log(errors);
      toast({
        title: 'Error sending invoice',
        description: `${Object.values(errors).filter(x => x).join(', ')}`,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }

    try {
      setIsSending(true);
      const response = await fetch(`${RuntimeConfig.backendOrigin}/quickbooks/invoice/send`, {
        method: 'POST',
        credentials: "include",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          invoiceId: quickbooksInvoice.Id,
          sendTo: values.to
        })
      });
  
      const result = await response.json();
  
      if (response.ok) {
        toast({
          title: 'Invoice sent',
          description: `The invoice has been sent successfully to ${result.data.sentTo} on ${new Date(result.data.sentDate).toLocaleString()}.`,
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        setIsSideDrawerOpen(false);
        // Update date_invoiced in the database
        console.log("Date invoiced sent from quickbooks: ", result.data.sentDate);
        await updateDatabaseDateInvoiced();
        // Redirect to client invoices page
        navigate(clientInvoicesPage.definition);
      } else {
        throw new Error(result.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error(error instanceof Error ? error.message : error);
      console.log(errors);
      toast({
        title: 'Internal server error',
        description: error instanceof Error ? error.message : "An unknown error occurred. Check the console for more information.",
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setIsSending(false);
    }
  }

  return (
    <main css={tw`w-full h-full overflow-y-auto`}>
      <h1 css={tw`text-2xl p-4 font-bold`}>Send Invoice</h1>
      <section css={tw`w-full flex justify-between gap-x-10`}>
        <Stack
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}          
          zIndex={200}
          css={tw`w-1/2 p-4 bg-white rounded shadow`}
        >
          <label htmlFor="to">
            To
            <Input 
              id="to" 
              name="to" 
              type="email"
              value={values.to}
              onChange={(e) => setValues({ ...values, to: e.target.value })}
            />
          </label>
          <label htmlFor="subject">
            Subject
            <Input 
              id="subject" 
              name="subject" 
              value={values.subject}  
              onChange={(e) => setValues({ ...values, subject: e.target.value })}
            />
          </label>
          <label htmlFor="body">
            Email Body
            <Textarea
              id="body" 
              name="body"
              h={60}
              value={values.body}
              onChange={(e) => setValues({ ...values, body: e.target.value })}
            />
          </label>
          {!isSending && (
              <Button 
                type="submit" 
                colorScheme="blue" 
                css={tw`mt-4`}
                disabled={invoiceAlreadySent}
              >
                {invoiceAlreadySent ? "We already sent this invoice" : "Send Invoice"}
              </Button>
            )
          }
          {isSending && <Spinner />}
        </Stack>
        <PdfViewer invoiceId={quickbooksInvoice.Id} width="100%" />
      </section>
    </main>
  );

}