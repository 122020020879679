import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import {
  Box,
  Heading,
  Button,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { useMemo, useState } from "react";
import {
  CellProps,
  Column,
  Renderer,
  useExpanded,
  useFilters,
  UseFiltersColumnOptions,
  UseFiltersColumnProps,
  UseGroupByColumnOptions,
  useSortBy,
  UseSortByColumnOptions,
  useTable,
} from "react-table";
import tw, { css } from "twin.macro";
import * as GraphQLTypes from "../../codegen/graphql-types";
import { FullSpinner } from "../../FullSpinner";
import { filterTypes } from "../../helpers/filterTypes";
import { PopoverInput } from "../../helpers/PopoverInput";
import { MultiSelectColumnFilter } from "../../Projects/Dashboard/MultiSelectColumnFilter";
import { TextTruncateRevealOnHover } from "../../Projects/Dashboard/TextTruncateRevealOnHover";
// auth
import { useAuthContext } from "../../useAuth";
import { useQuickbooksAuthContext } from "../../useQuickbooksAuth";
import LoginWithQuickbooks from "../../QuickBooks/LoginWithQuickbooks";
import LogoutFromQuickbooks from "../../QuickBooks/LogoutFromQuickbooks";
// components
//import { CreateQuickbooksInvoice } from "./New/CreateQuickbooksInvoice";
import { Link } from "react-router-dom";
import { INVOICE_PORTAL_URLS } from "./constants";
import SideDrawer from "../../Financials/Estimates/SideDrawer";
import PdfViewer from "../../Financials/Estimates/PdfViewer";

const currencyFormatter = new Intl.NumberFormat("en-US", {});
const EMPTY_ARRAY: Array<void> = [];

// const defaultSortByMemo = [
//   {
//     id: "dateCreated",
//     desc: true,
//   },
// ]

export const ClientInvoicesPage = () => {
  const { isAccounting } = useAuthContext();
  const { 
    isLoggedIntoQuickbooks,
    setIsLoggedIntoQuickbooks
  } = useQuickbooksAuthContext();

  const queryResult = useQuery<
    GraphQLTypes.ClientInvoicesPageQuery,
    GraphQLTypes.ClientInvoicesPageQueryVariables
  >(GraphQLTypes.ClientInvoicesPageDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const clientInvoices = queryResult?.data?.clientInvoices;

  const postProcessedRows = useMemo(() => {
    return _.orderBy(
      clientInvoices,
      [(x) => new Date(x.created_at).getTime()],
      ["desc"]
    );
  }, [clientInvoices]);

  const [sentInvoices, unsentInvoices] = useMemo(() => {
    const orderedInvoices = _.orderBy(
      clientInvoices,
      [(x) => new Date(x.created_at).getTime()],
      ["desc"]
    );

    const partitionResults = _.partition(
      orderedInvoices,
      (invoice) => !!invoice.date_invoiced
    );
    // console.log({ orderedInvoices, partitionResults });

    return partitionResults;
  }, [clientInvoices]);

  // console.log({ unsentInvoices, sentInvoices, postProcessedRows });

  return (
    <div css={tw`h-full p-6`}>
      <section css={tw`w-full flex justify-end`}>
        {isAccounting && !isLoggedIntoQuickbooks && (
          <LoginWithQuickbooks setIsAuthenticated={setIsLoggedIntoQuickbooks} />
        )}
        {isAccounting && isLoggedIntoQuickbooks && (
          <LogoutFromQuickbooks setIsAuthenticated={setIsLoggedIntoQuickbooks} />
        )}
      </section>

      <Heading size="md">Unsent Invoices</Heading>
      <ClientInvoicesTable
        postProcessedRows={unsentInvoices}
        isLoading={!!queryResult.data}
        sumInvoices={true}
      />

      <Heading size="md" tw="mt-4">
        Sent Invoices
      </Heading>
      <ClientInvoicesTable
        postProcessedRows={sentInvoices}
        isLoading={!!queryResult.data}
        sumInvoices={true}
      />
    </div>
  );
};

const ClientInvoicesTable = ({
  postProcessedRows,
  isLoading,
  sumInvoices
}: {
  postProcessedRows: GraphQLTypes.ClientInvoicesPageQuery["clientInvoices"];
  isLoading: boolean;
  sumInvoices: boolean;
}) => {
  const { isLoggedIntoQuickbooks } = useQuickbooksAuthContext();
  const [quickbooksInvoiceId, setQuickbooksInvoiceId] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [updateOneClientInvoice] = useMutation<
    GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageMutation,
    GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageMutationVariables
  >(GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageDocument);

  const columnDefinitions = useMemo(() => {
    if (!postProcessedRows) return [];
    type Row = typeof postProcessedRows[number];
    type DataColumn = UseSortByColumnOptions<Row> &
      UseFiltersColumnOptions<Row> &
      UseGroupByColumnOptions<Row> &
      Column<Row> & {
        Header2?: Renderer<UseFiltersColumnProps<Row>>;
        align?: "left" | "center" | "right";
      };
    const NONE = `(None)`;

    // const years = _.uniq(
    //   postProcessedRows.flatMap((row) => {
    //     if (!row.created_at) return [];
    //     return getYear(new Date(row.created_at));
    //   })
    // );

    const columns: Array<
      DataColumn | { id: string; columns: Array<DataColumn> }
    > = [
      {
        id: "dateCreated",
        Header: "Date Created",
        align: "left",
        accessor: (x) => new Date(x.created_at).getTime(),
        Cell: ({ cell }: CellProps<Row>) => (
          <TextTruncateRevealOnHover
            css={css`
              max-width: 10rem;
            `}
            text={new Date(cell.value).toLocaleString("en-CA")}
          />
        ),
      },
      {
        id: "createInvoice",
        Header: "Invoice Status",
        align: "center",
        accessor: (x) => x.id,
        Cell: ({ cell, row }: CellProps<Row>) => {
          const quickbooksId = row.original.quickbooks_id;
          const companyName = row.original.estimate.company.company_name;
          const dateInvoiced = row.original.date_invoiced;
          const companyHasPortalUrl = INVOICE_PORTAL_URLS[companyName] as string | undefined;

          if (dateInvoiced) return "Invoiced";     

          // If there is a quickbooks id, then the invoice has been created
          // Return a link to the portal if the company has a portal url
          if (quickbooksId && companyHasPortalUrl) {
            return (
              <a
                target="_blank"
                href={companyHasPortalUrl}
                rel="noreferrer"
                style={{ 
                  boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                  border: "1px solid red",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "white",
                  backgroundColor: "red",
                  fontWeight: "bold",
                }}
              >
                Go to Portal
              </a>
            )
          }

          return isLoggedIntoQuickbooks ? (
            <Link 
              style={{ 
                boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                border: "1px solid #16a41c",
                padding: "5px",
                borderRadius: "5px",
                color: "white",
                backgroundColor: "#16a41c",
                fontWeight: "bold",
              }} 
              to={`/financials/invoices/new?id=${cell.value}`}
            >
              {quickbooksId ? "Go to Invoice" : "Create Invoice"}
            </Link>
          ) : (
            <></>
          );
        },
      },
      {
        id: "estimateName",
        Header: (
          <Box w="full" textAlign="left">
            Estimate Name
          </Box>
        ),
        accessor: (x) => x.estimate.name,
        Cell: ({ cell }: CellProps<Row>) => (
          <TextTruncateRevealOnHover
            css={css`
              max-width: 10rem;
            `}
            text={cell.value ?? ""}
          />
        ),
      },
      {
        id: "invoiceNumber",
        Header: "Invoice #",
        accessor: (x) => x.invoice_number,
        Cell: ({ cell, row }: CellProps<Row>) => (
          <PopoverInput
            initialValue={cell.value}
            displayText={cell.value ?? NONE}
            onSubmit={async (e, newValue) => {
              await updateOneClientInvoice({
                variables: {
                  updateOneClientInvoiceData: {
                    invoice_number: {
                      set: newValue,
                    },
                  },
                  updateOneClientInvoiceWhere: {
                    id: row.original.id,
                  },
                },
              });
            }}
          />
        ),
      },

      {
        id: "invoiceAmount",
        Header: (
          <Box w="full" textAlign="right">
            Invoice amount
          </Box>
        ),
        ...(sumInvoices
          ? {
              Filter: ({
                column: { preFilteredRows },
              }: {
                column: UseFiltersColumnProps<Record<string, unknown>> & { id: string };
              }) => {
                return (
                  <Box textAlign="right">
                    $
                    {currencyFormatter.format(
                      preFilteredRows.reduce(
                        (acc, a) => acc + a.values.invoiceAmount,
                        0
                      ) / 100
                    )}
                  </Box>
                );
              },
            }
          : null),
        accessor: (x) => Number(x.amount_in_cents),
        Cell: ({ cell, row }: CellProps<Row>) => row.original.quickbooks_id ? <>{"$" + currencyFormatter.format(cell.value / 100)}</> : (
          <PopoverInput
            initialValue={cell.value / 100}
            type="number"
            displayText={
              cell.value
                ? "$" + currencyFormatter.format(cell.value / 100)
                : NONE
            }
            onSubmit={async (e, newValue) => {
              await updateOneClientInvoice({
                variables: {
                  updateOneClientInvoiceData: {
                    amount_in_cents: {
                      set: Math.round(Number(newValue ?? 0) * 100),
                    },
                  },
                  updateOneClientInvoiceWhere: {
                    id: row.original.id,
                  },
                },
              });
            }}
          />
        ),
      },

      {
        id: "estimateNumber",
        Header: (
          <Box w="full" textAlign="left">
            PO #
          </Box>
        ),
        accessor: (x) => x.estimate.po_number,
        Cell: ({ cell }: CellProps<Row>) => cell.value,
      },

      // {
      //   id: "estimate_status",
      //   Header: (
      //     <Box w="full" textAlign="left">
      //       Estimate Status
      //     </Box>
      //   ),
      //   accessor: (x) => x.estimate.estimate_status,
      //   Cell: ({ cell, row }: CellProps<Row>) => {
      //     switch (cell.value) {
      //       case "INVOICE_SUBMITTABLE": {
      //         return "READY TO SUBMIT INVOICE";
      //       }
      //       default: {
      //         return cell.value;
      //       }
      //     }
      //   },

      //   Filter: MultiSelectColumnFilter,
      //   filter: "multiple",
      // },

      {
        id: "estimateCompany",
        Header: (
          <Box w="full" textAlign="left" minWidth="120px">
            Company
          </Box>
        ),
        accessor: (x) => x.estimate.company.company_name,
        Cell: ({ cell }: CellProps<Row>) => cell.value,
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
      },
      {
        id: "estimateCompanyBrand",
        Header: (
          <Box w="full" textAlign="left">
            Brand
          </Box>
        ),
        accessor: (x) => x.estimate.brand.brand_name,
        Cell: ({ cell }: CellProps<Row>) => cell.value,
      },
      {
        id: "dateInvoiced",
        Header: "Date Invoiced",
        accessor: (x) => x.date_invoiced,
        Cell: ({ cell, row }: CellProps<Row>) => {
          return (
            <PopoverInput
              initialValue={
                cell.value
                  ? new Date(cell.value).toLocaleDateString("en-CA", {
                      timeZone: "UTC",
                    })
                  : new Date().toLocaleDateString("en-CA", {
                      timeZone: "UTC",
                    })
              }
              displayText={
                cell.value
                  ? new Date(cell.value).toLocaleDateString("en-CA", {
                      timeZone: "UTC",
                    })
                  : NONE
              }
              type="date"
              onSubmit={async (e, newValue) => {
                await updateOneClientInvoice({
                  variables: {
                    updateOneClientInvoiceData: {
                      date_invoiced: {
                        set: new Date(newValue),
                      },
                    },
                    updateOneClientInvoiceWhere: {
                      id: row.original.id,
                    },
                  },
                });
              }}
            />
          );
        },
      },
      {
        id: "quickbooksId",
        Header: "Quickbooks PDF",
        accessor: (x) => x.quickbooks_id,
        Cell: ({ cell }: CellProps<Row>) => {
          const invoiceId = cell.value;
          if (!invoiceId) return null;
          return (
            <div css={tw`w-full flex justify-center`}>
              <Button
                size="sm"
                onClick={() => {
                  setQuickbooksInvoiceId(invoiceId);
                  setIsDrawerOpen(true);
                }}
              >
                <DownloadIcon />
              </Button>
            </div>
          );
        }
      },
    ];
    return columns.map((columnDefinition) => ({
      ...columnDefinition,
      ...("sortDescFirst" in columnDefinition ? null : { sortDescFirst: true }),
    }));
  }, [postProcessedRows, updateOneClientInvoice, isLoggedIntoQuickbooks]);

  const {
    // allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    // state: { expanded },
  } = useTable(
    {
      columns: columnDefinitions,
      data: postProcessedRows ?? EMPTY_ARRAY,
      defaultColumn: {
        // Let's set up our default Filter UI
        // @ts-expect-error
        Filter: () => null,
        sortType: "alphanumeric",
      },
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
      // autoResetGroupBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      initialState: {
        // hiddenColumns: initiallyHiddenColumns,
        // sortBy: defaultSortByMemo,
      },
    },
    useFilters,
    // useGroupBy,
    useSortBy,
    useExpanded
  );

  if (!isLoading) return <FullSpinner />;
  if (!postProcessedRows) return null;

  return (
    <div
      css={[
        css`
          min-width: 100%;
          width: fit-content;
        `,
      ]}
    > 
      {isDrawerOpen && quickbooksInvoiceId && (
        <SideDrawer
          isOpen={isDrawerOpen}
          onClose={() => {
            setQuickbooksInvoiceId(null);
            setIsDrawerOpen(false);
          }}
        >
          <PdfViewer invoiceId={quickbooksInvoiceId} />
        </SideDrawer>
      )}
      <div>
        <table
          {...getTableProps()}
          css={[
            tw`text-sm w-full`,
            css`
              background-color: #fbfbfb;
              .row-hover-visible {
                opacity: 0;
              }
              & tr:hover .row-hover-visible {
                opacity: 1;
              }

              .cell-hover-visible {
                opacity: 0;
              }
              & td:hover .cell-hover-visible {
                opacity: 1;
              }
            `,
          ]}
        >
          <thead
            css={css`
              &,
              & > tr > th {
                background-color: #f0f0f0;
                border-right: solid 1px #ddd;
                border-left: solid 1px #ddd;
              }
              border-top: solid 1px #ddd;
            `}
          >
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      css={css`
                        padding: 0.5rem;
                        top: 0;
                        ${!column.placeholderOf &&
                        css`
                          border-bottom: solid 1px #ddd;
                        `}
                        ${headerGroups.length === 1 ||
                        headerGroups.length - 2 < headerGroupIndex
                          ? css`
                              position: sticky;
                            `
                          : ""}
                      border-left: solid 1px #ddd;
                        border-right: solid 1px #ddd;
                        box-sizing: border-box;

                        color: black;
                        font-weight: 500;
                      `}
                      // @ts-expect-error
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div style={{ display: "flex" }}>
                        <>
                          {column.render("Header")}
                          {headerGroups.length === 1 ||
                            (headerGroups.length - 2 < headerGroupIndex && (
                              <span>
                                {
                                  // @ts-expect-error
                                  column.isSorted
                                    ? // @ts-expect-error
                                      column.isSortedDesc
                                      ? " 🔽"
                                      : " 🔼"
                                    : " ↕️"
                                }
                              </span>
                            ))}
                        </>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
            <tr key="aggregateHeaderRow">
              {headerGroups.slice(-1)[0].headers.map((column) => {
                return (
                  <th
                    key={column.id}
                    css={css`
                      background: aliceblue;
                      padding: 0.5rem;
                      border-left: solid 1px #ddd;
                      border-right: solid 1px #ddd;
                      border-bottom: solid 1px #ddd;
                      box-sizing: border-box;

                      color: black;
                      font-weight: 500;
                    `}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {
                        // @ts-ignore
                        column.Header2 && column.render("Header2")
                      }
                    </div>
                    {
                      // @ts-expect-error
                      column.canFilter && column.render("Filter")
                    }
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody
            {...getTableBodyProps()}
            css={css`
              & > tr {
                content-visibility: auto;
                contain-intrinsic-size: 1000px;
                & > td {
                  border: solid 1px #ddd;
                  padding: 0.5rem;
                }
              }
            `}
          >
            {rows.length === 0 && (
              <tr>
                <td colSpan={visibleColumns.length} align="center">
                  no invoices
                </td>
              </tr>
            )}
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          textAlign:
                            // @ts-expect-error
                            cell.column.align
                              ? // @ts-expect-error
                                cell.column.align
                              : typeof cell.value === "number" ||
                                (typeof cell.value === "string" &&
                                  cell.value.startsWith("$"))
                              ? "right"
                              : "left",
                        }}
                      >
                        <>
                          {cell.render("Cell")}
                        </>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div> 
  );
};