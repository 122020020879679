
import tw from "twin.macro";
import { css } from "@emotion/react";
import _ from "lodash";
import {
  Avatar,
  Button,
  ButtonProps,
  Flex,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  IconButton,
  useClipboard,
} from "@chakra-ui/react";
import { ComponentProps, useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import Select from "react-select";
import { NavQuery, useNavQuery } from "../codegen/graphql-types";
import * as routes from "../routes";
import { useAuthContext } from "../useAuth";
import { peopleIdsToExclude } from "../Time/constants";
import { createIcon } from "@chakra-ui/icons";

export const CopyIcon = createIcon({
  displayName: "CopyIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
    />
  ),
});

const PodInfo = ({ pod }: { pod: any }) => {
  const { onCopy, hasCopied } = useClipboard(pod.id);

  return (
    <>
      <dt>Pod</dt>
      <dd>{pod.name}</dd>
      <dt>Pod ID</dt>
      <dd>
        <IconButton
          aria-label="Copy pod ID"
          icon={<CopyIcon />}
          onClick={onCopy}
          size="xs"
          colorScheme={hasCopied ? "green" : "blue"}
        />
      </dd>
      <dd css={tw`col-span-2`} style={{ fontSize: "0.8rem" }}>{pod.id}</dd>
      <dt>Members</dt>
      <dd css={tw`col-span-2`}>
        <ul css={tw`list-disc`}>
          {pod.members.map((person: any) => (
            <li key={person.id} css={tw`ml-4`}>
              {person.first_name} {person.last_name}
            </li>
          ))}
        </ul>
      </dd>
    </>
  );
};

export const Nav = () => {
  const { signOut, isAdmin, isManager, canBeAdmin } = useAuthContext();
  const { data, loading } = useNavQuery();

  return (
    <>
      <Flex
        css={[
          tw`print:hidden z-40 flex items-center bg-gray-800 py-2 px-6 relative fixed top-0 left-0 w-full`,
          css`max-width: 100vw;`,
        ]}
      >
        {/* Financial Nav Links */}
        <NavGroup title="Financials">
          <NavButtonLink to={routes.grossProfitReport.getInstance()}>
            Performance
          </NavButtonLink>
          <NavButtonLink to={routes.estimatesPage.getInstance()}>
            Estimates & POs
          </NavButtonLink>
          <NavButtonLink to={routes.clientInvoicesPage.getInstance()}>
            Invoices
          </NavButtonLink>
        </NavGroup>

        <VerticalDivider />

        {/* Project Nav Links */}
        <NavGroup title="Projects">
          <NavButtonLink to={routes.dashboardPage.getInstance()}>
            Dashboard
          </NavButtonLink>
          <NavButtonLink to={routes.newExpense.getInstance()}>
            Expenses
          </NavButtonLink>
        </NavGroup>

        <VerticalDivider />

        {/* Time Nav Links */}
        <NavGroup title="Time">
          <NavButtonLink to={routes.timeEmployee.getInstance()}>
            Employee
          </NavButtonLink>
          <NavButtonLink to={routes.timePod.getInstance()}>Pod</NavButtonLink>
        </NavGroup>

        {/* Admin Nav Links */}
        {isAdmin && (
          <>
            <VerticalDivider />

            <NavGroup title="Admin">
              <NavButtonLink to={routes.adminTime.getInstance()}>
                Time
              </NavButtonLink>
              <NavButtonLink to={routes.adminForecasting.getInstance()}>
                Forecasting
              </NavButtonLink>
              <NavButtonLink to={routes.adminTimeByMonth.getInstance()}>
                Time By Month
              </NavButtonLink>
            </NavGroup>
          </>
        )}

        <Stack direction="row" marginLeft="auto" alignItems="center">
          {canBeAdmin || isManager ? (
            <AdminUserOverride />
          ) : (
            <Avatar size="sm" src={data?.me?.avatar_url} />
          )}
          {!loading && data && (
            <Popover>
              <PopoverTrigger>
                <Button
                  size="sm"
                  variant="ghost"
                  colorScheme="whiteAlpha"
                  color="#ffffff"
                >
                  {data.me?.first_name}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                style={{ backgroundColor: "#1F2937", color: "#fff" }}
              >
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>
                  {data.me?.first_name} {data.me?.last_name}
                </PopoverHeader>
                <PopoverBody>
                  <dl css={tw`grid grid-cols-[auto,1fr] gap-x-4 gap-y-2`}>
                    <dt css={tw`font-semibold`}>ID</dt>
                    <dd>{data.me?.id}</dd>

                    <dt css={tw`font-semibold`}>Email</dt>
                    <dd>{data.me?.email_address}</dd>

                    {_.map(data.me?.pods, (pod) => (
                      // @ts-ignore
                      <PodInfo key={pod.id} pod={pod} />
                    ))}
                  </dl>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}{" "}
          <Button
            size="sm"
            onClick={signOut}
            variant="ghost"
            colorScheme="whiteAlpha"
            color="#ffffff"
            ml={4}
          >
            Sign Out
          </Button>
        </Stack>
      </Flex>
    </>
  );
};

const AdminUserOverride = () => {
  const {
    loggedInUserData,
    setAdminUserOverride,
  } = useAuthContext();
  const { data } = useNavQuery();
  const [shouldShowDropdown, setShouldShowDropdown] = useState(false);
  const [overrideUser, setOverrideUser] = useState<NavQuery["people"][number] | null>(null);

  const excludeCertainPeople = (person: {
    value: string;
    label: string;
    rawValue: NavQuery["people"][number];
  }) => {
    return !peopleIdsToExclude.includes(person.value);
  };

  return (
    <div style={{ width: 130, marginRight: 10 }}>
      {shouldShowDropdown ? (
        <Select
          autoFocus
          onBlur={() => setShouldShowDropdown(false)}
          menuIsOpen
          styles={{
            option: (provided: any) => ({
              ...provided,
              zIndex: 9999, // Ensure it's high enough
            }),
          }}
          onChange={(selectedOption: any) => {
            console.log(selectedOption);
            if (!selectedOption) return;
            setShouldShowDropdown(false);
            setAdminUserOverride(selectedOption.rawValue.email_address);
            setOverrideUser(selectedOption.rawValue);
          }}
          options={_.orderBy(data?.people, (x) => x.first_name)
            .flatMap((person: any) => {
              if (!person.email_address.endsWith("@mavenmm.com")) {
                return [];
              }
              return {
                value: person.id,
                label: `${person.first_name} ${person.last_name}`,
                rawValue: person,
              };
            })
            .filter(excludeCertainPeople)}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
        />
      ) : (
        <NavButton
          onClick={() => setShouldShowDropdown(true)}
          disabled={shouldShowDropdown}
        >
          Viewing As{" "}
          <Avatar
            size="sm"
            ml="2"
            src={
              overrideUser
                ? overrideUser.avatar_url
                : loggedInUserData?.profileImage
            }
          />
        </NavButton>
      )}
    </div>
  );
};


const NavGroup = ({ title, children }: { title: string; children: any }) => {
  return (
    <nav className="hide-for-print" css={[tw`flex flex-col items-center mx-2`]}>
      <span css={tw`text-gray-200 opacity-50 text-sm`}>{title}</span>
      <div css={tw`flex`}>{children}</div>
    </nav>
  );
};

const VerticalDivider = () => {
  return (
    <div
      className="hide-for-print"
      css={[
        tw`border-white h-10 border-l-2 border-solid border-white`
      ]}
    ></div>
  );
};

interface NavButtonLinkProps extends ButtonProps {
  to: NavLinkProps['to'];
  children: React.ReactNode;
}

const NavButtonLink: React.FC<NavButtonLinkProps> = ({ to, children, ...rest }) => {
  return (
    <NavButton as={NavLink} to={to} {...rest}>
      {children}
    </NavButton>
  );
};

const NavButton = (props: ComponentProps<typeof Button>) => {
  return (
    <Button
      variant="ghost"
      css={tw`ml-1 first:ml-0 text-gray-100 hover:bg-gray-700 [&.active]:text-white [&.active]:bg-gray-900`}
      size="sm"
      {...props}
    />
  );
};
