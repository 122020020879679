import {
  useState,
  createContext,
  useContext,
  useEffect,
  // useCallback,
} from "react";
import { RuntimeConfig } from "./RuntimeConfig";

const QuickbooksAuthContext = createContext<ReturnType<typeof useQuickbooksAuth> | null>(null);

export const QuickbooksAuthContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const authData = useQuickbooksAuth();

  return (
    <QuickbooksAuthContext.Provider value={authData}>{children}</QuickbooksAuthContext.Provider>
  );
};

export const useQuickbooksAuthContext = () => {
  const context = useContext(QuickbooksAuthContext);
  if (!context) {
    throw new Error("useQuickbooksAuthContext must be used within a AuthContextProvider");
  }
  return context;
};

const useQuickbooksAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkJWTExpiration = () => {
    // console.log("Checking JWT expiration...");
    const qbJWT = localStorage.getItem("qbJWT");
    if (qbJWT) {
      const jwt = JSON.parse(qbJWT);
      const expiresAt = jwt.expiresAt;
      const currentTime = Math.floor(Date.now() / 1000);
      // console.log("JWT is valid until", new Date(expiresAt * 1000).toLocaleString());
      return expiresAt < currentTime;
    }
    return true;
  };

  const quickbooksLogout = async () => {
    console.log("Logging out of Quickbooks...");
    try {
      const response = await fetch(`${RuntimeConfig.backendOrigin}/quickbooks/logout`, {
        credentials: 'include',
      });
      if (response.ok) {
        setIsAuthenticated(false);
        localStorage.removeItem("qbJWT");
      }
    } catch (error) {
      console.error("quickbooksLogout error", error);
    }
  };

  const checkAuthStatus = async () => {
    try {
      const response = await fetch(`${RuntimeConfig.backendOrigin}/quickbooks/auth-status`, {
        credentials: 'include',
      });
      if (response.ok) {
        setIsAuthenticated(true);
      } else {
        await quickbooksLogout();
      }
    } catch (error) {
      await quickbooksLogout();
    }
  };

  useEffect(() => {
    const handleExpiration = () => {
      if (checkJWTExpiration()) {
        console.log("JWT has expired, logging out...");
        quickbooksLogout();
      } else {
        checkAuthStatus();
      }
    };

    handleExpiration(); // Check immediately on mount

    const expirationCheckInterval = setInterval(handleExpiration, 60000); // Check every minute

    return () => clearInterval(expirationCheckInterval);
  }, []);

  return {
    isLoggedIntoQuickbooks: isAuthenticated,
    setIsLoggedIntoQuickbooks: setIsAuthenticated,
    quickbooksLogout,
  };
};
