import tw from "twin.macro";
import { useEffect, useState } from 'react';
import { RuntimeConfig } from '../../RuntimeConfig';
import { Spinner } from '@chakra-ui/react';

export default function PdfViewer({ s3Key, invoiceId, width }: { s3Key?: string, invoiceId?: string, width?: string }) {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    const fetchPdf = async ({ url }: { url: string }) => {
      try {
        const response = await fetch(url, {
          credentials: 'include',
          headers: {
            'x-api-key': import.meta.env.VITE_S3_API_KEY!,
          }
        });
        if (!response.ok) {
          const errorData = await response.json();
          console.log("Get PDF Error: ", errorData);
          throw new Error(`Intuit_tid: ${errorData.intuit_tid} - ${errorData.message}. Details: ${errorData.messageDetail}`);
        }
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setPdfUrl(objectUrl);
      } catch (error) {
        console.error('Error fetching PDF:', error);
        // Handle error (e.g., set an error state)
        setError(`Failed to fetch PDF - ${error}`);
      } finally {
        setLoading(false);
      }
    };

    if (s3Key) {
      fetchPdf({
        url: `${RuntimeConfig.backendOrigin}/s3/download?key=${s3Key}`,
      });
    } else if (invoiceId) {
      fetchPdf({
        url: `${RuntimeConfig.backendOrigin}/quickbooks/invoice/pdf?invoiceId=${invoiceId}`,
      });
    }

    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [s3Key, invoiceId]);

  return (
    <div css={tw`w-full flex justify-self-end`}>
      {pdfUrl && (
        <iframe
          title="PDF Viewer"
          src={pdfUrl}
          width={width || '100%'}
          height="800px"
        />
      )}
      {loading && <Spinner />}
      {error && <p>{error}</p>}
    </div>
  );
}