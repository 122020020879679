import tw from "twin.macro";
import { useQuery, useMutation } from "@apollo/client";
import {
  Button,
  useToast,
  Select,
  Input,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import { PopoverInput } from "../../../helpers/PopoverInput";
import { useState } from "react";
import { RuntimeConfig } from "../../../RuntimeConfig";
import { useAuthContext } from "../../../useAuth";
import { useQuickbooksAuthContext } from "../../../useQuickbooksAuth";
import { useQuickbooksResourceContext } from "../../../helpers/useQuickbooksContext";
import LoginWithQuickbooks from "../../../QuickBooks/LoginWithQuickbooks";
import LogoutFromQuickbooks from "../../../QuickBooks/LogoutFromQuickbooks";
import * as QBO from "../../../../../server/lib/quickbooks/types";
import LineItemsTable from "./LineItemsTable";
import SendInvoiceWithQuickbooks from "./SendInvoiceWithQuickbooks";
import SideDrawer from "../../Estimates/SideDrawer";
import PdfViewer from "../../Estimates/PdfViewer";
// import type * as GraphQLTypes from "../../../codegen/graphql-types";
import * as GraphQLTypes from "../../../codegen/graphql-types";
import { INVOICE_PORTAL_URLS } from "../constants";
import { useNavigate } from 'react-router-dom';
import { clientInvoicesPage } from "../../../routes";

// export const ClientNewInvoiceFragment = gql`
//   fragment ClientNewInvoiceFragment on ClientInvoice {
//     id
//     created_at
//     invoice_number
//     date_invoiced
//     amount_in_cents
//     quickbooks_id
//     estimate {
//       id
//       name
//       po_number
//       client_invoice_count
//       pdf_file_key
//       company {
//         id
//         company_name
//       }
//       brand {
//         id
//         brand_name
//       }
//       # estimate_status
//     }
//   }
// `;

type InvoiceFragment = {
  id: string;
  created_at: string;
  invoice_number: string;
  date_invoiced: string;
  amount_in_cents: number;
  quickbooks_id: string;
  estimate: {
    id: string;
    name: string;
    po_number: string;
    client_invoice_count: number;
    pdf_file_key: string;
    company: {
      id: string;
      company_name: string;
    };
    brand: {
      id: string;
      brand_name: string;
    };
  };
};

export const CreateQuickbooksInvoice = () => {
  // get the invoice id from the URL
  const queryParams = new URLSearchParams(window.location.search);
  const invoiceId = queryParams.get("id") ?? "";

  // fetch the invoice data from the server
  const { loading, error, data } = useQuery(GraphQLTypes.GetClientInvoiceDocument,
    {
      variables: { id: invoiceId }
    }
  );

  if (loading)
    return (
      <div css={tw`h-full p-6`}>
        <section css={tw`w-full flex flex-col justify-center items-center`}>
          <p>Fetching Invoice data from Dashboard database...</p>
          <br />
          <br />
          <Spinner />
        </section>
      </div>
    );

  if (error)
    return (
      <div css={tw`h-full p-6`}>
        <section css={tw`w-full flex justify-center items-center`}>
          <p style={{ color: "red" }}>Error: {error.message}</p>
        </section>
      </div>
    );

  return <GetQuickbooksResources dashboardInvoiceData={data.clientInvoiceById} />;
};

const GetQuickbooksResources = ({
  dashboardInvoiceData,
}: {
  dashboardInvoiceData: InvoiceFragment;
}) => {
  const { qbResources, isLoading, error } = useQuickbooksResourceContext();

  if (isLoading)
    return (
      <div css={tw`h-full p-6`}>
        <section css={tw`w-full flex flex-col justify-center items-center`}>
          <p>Fetching data from Quickbooks...</p>
          <br />
          <br />
          <Spinner />
        </section>
      </div>
    );

  if (error)
    return (
      <div css={tw`h-full p-6`}>
        <section css={tw`w-full flex justify-center items-center`}>
          <p style={{ color: "red" }}>
            Error fetching data from quickbooks: {error.message}
          </p>
        </section>
      </div>
    );

  if (qbResources) {
    return (
      <NewQuickbooksInvoice
        dashboardInvoiceData={dashboardInvoiceData}
        qbResources={qbResources as QuickbooksResources}
      />
    );
  }

  return <p>"You may need to log in to Quickbooks"</p>;
};

// TODO: Make these types not be | null
export type QuickbooksResources = {
  Customer: QBO.Customer[];
  Invoice: QBO.Invoice[];
  Item: QBO.Item[];
  Term: QBO.Term[];
  TaxRate: QBO.TaxRate[];
  TaxCode: QBO.TaxCode[];
};

interface CreateQuickbooksInvoiceProps {
  customerId: string;
  customerDisplayName: string;
  customerEmail: string;
  purchaseOrderNumber: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceTerm: QBO.Term | undefined;
  invoiceDueDate: string;
  lineItems: QBO.InvoiceLine[];
}

const NewQuickbooksInvoice = ({
  dashboardInvoiceData,
  qbResources,
}: {
  dashboardInvoiceData: InvoiceFragment;
  qbResources: QuickbooksResources;
}) => {
  const { isAccounting } = useAuthContext();
  const navigate = useNavigate();
  const {
    isLoggedIntoQuickbooks,
    setIsLoggedIntoQuickbooks,
  } = useQuickbooksAuthContext();

  const [updateOneClientInvoice] = useMutation<
    GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageMutation,
    GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageMutationVariables
  >(GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageDocument);

  const alreadyCreatedQBInvoice = !!dashboardInvoiceData.quickbooks_id;
  const databaseInvoiceId = dashboardInvoiceData.id;
  const companyName = dashboardInvoiceData.estimate.company.company_name;
  const brandName = dashboardInvoiceData.estimate.brand.brand_name;
  const estimateName = dashboardInvoiceData.estimate.name;
  const purchaseOrderNumber = dashboardInvoiceData.estimate.po_number;
  // const invoiceNumber = dashboardInvoiceData.invoice_number; // database invoice number
  const invoiceAmount = dashboardInvoiceData.amount_in_cents / 100;
  const estimateFileKey = dashboardInvoiceData.estimate.pdf_file_key;
  
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const makeErrorToast = (title: string, message: string) => {
    toast({
      title: title,
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  const handleSuccess = () => {
    toast({
      title: "Invoice created in QuickBooks & Updated in Dashboard",
      description: `Invoice no. was updated.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  // Get the PO number custom field from the first invoice - trying to guess the name of the PO field to get the Id specific to the company account
  const poNumberCustomField =
    qbResources.Invoice?.length > 0
      ? qbResources.Invoice[0].CustomField.find((field) => field.Name === "PO")
      : null;

  // Do the same thing with Invoice Number custom field
  const invoiceNumberCustomField =
    qbResources.Invoice?.length > 0
      ? qbResources.Invoice[0].CustomField.find(
          (field) => field.Name === "Invoice no."
        )
      : null;

  // Get the Sales item from the items list
  const salesItem = qbResources.Item?.find(
    (item) => item.FullyQualifiedName === "Sales"
  );
  const salesItemValue = salesItem ? salesItem.Id : "";

  // Find the customer by the company name + brand name (e.g. "Company - Brand") as it is saved in Quickbooks
  const customer = qbResources.Customer?.find(
    (customer) =>
      customer.DisplayName.toLowerCase() ===
      `${companyName.toLowerCase()} - ${brandName.toLowerCase()}`
  );
  const customerId = customer ? customer.Id : "";
  const customerDisplayName = customer ? customer.DisplayName : "";

  // Find the SalesTermRef id for the customer - this is the payment term for the customer i.e. Net 30, Net 60, etc.
  const salesTermRefId = customer ? customer.SalesTermRef?.value : "";
  const defaultSalesTerm = qbResources.Term?.find(
    (term: QBO.Term) => term.Name === "Net 30"
  );
  const salesTermForCustomer = qbResources.Term?.find(
    (term: QBO.Term) => term.Id === salesTermRefId
  );
  const dueDaysForCustomer = salesTermForCustomer
    ? salesTermForCustomer.DueDays
    : defaultSalesTerm?.DueDays ?? 30;

  // Find the customer's default tax code reference id i.e. "1"
  const defaultTaxCodeId = customer ? customer.DefaultTaxCodeRef.value : "";
  // const defaultTaxCodeName = hstONTaxCode ? hstONTaxCode.SalesTaxRateList.TaxRateDetail[0].TaxRateRef.name : "";
  const defaultTaxCode = qbResources.TaxCode?.find(
    (taxCode) => defaultTaxCodeId === taxCode.Id
  );
  const defaultTaxCodeName = defaultTaxCode ? defaultTaxCode.Name : "";

  const invoiceNumber = estimateName.split(" ")[1] ?? "";
  const invoiceDashNumber = dashboardInvoiceData.estimate.client_invoice_count;
  const invoiceNumberWithDash = invoiceNumber ? `${invoiceNumber}-${invoiceDashNumber}` : '';

  const initialValues = {
    customerId: customerId,
    customerDisplayName: customerDisplayName,
    customerEmail: customer?.PrimaryEmailAddr?.Address ?? "",
    purchaseOrderNumber: purchaseOrderNumber,
    invoiceNumber: invoiceNumberWithDash,
    invoiceDate: new Date().toLocaleDateString("en-CA", { timeZone: "UTC" }), // today's date
    invoiceTerm: salesTermForCustomer ?? defaultSalesTerm,
    invoiceDueDate: new Date(
      new Date().setDate(new Date().getDate() + dueDaysForCustomer)
    ).toLocaleDateString("en-CA", { timeZone: "UTC" }),
    lineItems: [
      {
        DetailType: "SalesItemLineDetail",
        Description: "",
        Amount: Number(invoiceAmount),
        SalesItemLineDetail: {
          ItemRef: {
            value: salesItemValue,
          },
          UnitPrice: Number(invoiceAmount),
          Qty: 1,
          TaxCodeRef: {
            value: defaultTaxCodeId,
            name: defaultTaxCodeName,
          },
        },
      },
    ],
  };

  // Values we will use to make the post request to create the invoice
  const [values, setValues] = useState<CreateQuickbooksInvoiceProps>(
    initialValues
  );
  // warning message if no customer is found
  const [warning, setWarning] = useState(!customerId);
  // viewing the estimate pdf
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  // invoice from quickbooks after creation
  const [quickbooksInvoice, setQuickbooksInvoice] = useState<QBO.Invoice | null>(null);

  const createQuickbooksInvoice = async (postBody: QBO.InvoicePostRequestBody) => {
    const response = await fetch(
      `${RuntimeConfig.backendOrigin}/quickbooks/invoice`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postBody),
      }
    );
  
    if (!response.ok) {
      throw new Error(`Error in createQuickbooksInvoice. Status: ${response.status}`);
    }
  
    const json = await response.json();

    return json.data;
  };

  const updateDashboardInvoice = async (quickbooksInvoiceId: string, quickbooksInvoiceNumber: string) => {
    try {
      return await updateOneClientInvoice({
        variables: {
          updateOneClientInvoiceData: {
            quickbooks_id: {
              set: quickbooksInvoiceId,
            },
            invoice_number: {
              set: quickbooksInvoiceNumber
            },
          },
          updateOneClientInvoiceWhere: {
            id: databaseInvoiceId,
          },
        },
      });
    } catch (error) {
      console.error(error);
      throw new Error(`Error in updateDashboardInvoice: Could not update database invoice (Invoice: ${quickbooksInvoiceNumber}) with Quickbooks invoice ID: ${quickbooksInvoiceId}`);
    }
  };

  const handleCreateInvoice = async (postBody: QBO.InvoicePostRequestBody) => {
    try {
      setIsLoading(true);
      const data: {
        Invoice: QBO.Invoice;
        time: string; // "2021-09-29T17:00:00.000-07:00"
      } = await createQuickbooksInvoice(postBody);

      const quickbooksInvoiceNumber = data.Invoice.CustomField.find(
        (field) => field.Name === "Invoice no."
      )?.StringValue ?? "";

      if (!quickbooksInvoiceNumber) {
        throw new Error("Quickbooks invoice number not found in custom fields");
      }

      const databaseResponse = await updateDashboardInvoice(data.Invoice.Id, quickbooksInvoiceNumber);
        
      if (databaseResponse.data?.updateOneClientInvoice) {
        handleSuccess();
        const databaseEstimateCompanyName = dashboardInvoiceData.estimate.company.company_name;
        const companyHasPortalUrl = INVOICE_PORTAL_URLS[databaseEstimateCompanyName];
        
        // Move on to Invoice Sending...
        if (companyHasPortalUrl) {
          // redirect to invoices page
          navigate(clientInvoicesPage.definition);
        } else {
          console.log("Invoice created in Quickbooks. Open Send to customer dialogue...");
          // open dialogue to view invoice pdf and send to customer email
          setIsSideDrawerOpen(true);
          setQuickbooksInvoice(data.Invoice);
        }
      }
    } catch (error: any) {
      console.error("Error creating invoice: ", error);
      makeErrorToast("Error creating invoice: ", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const createPostBody = (): QBO.InvoicePostRequestBody => {
    return  {
      BillEmail: {
        Address: values.customerEmail,
      },
      CustomerRef: {
        value: values.customerId,
      },
      Line: values.lineItems,
      DueDate: values.invoiceDueDate,
      CustomerMemo: {
        value: `PO# ${values.purchaseOrderNumber}`,
      },
      CustomField: [
        {
          DefinitionId: poNumberCustomField?.DefinitionId ?? "1",
          StringValue: values.purchaseOrderNumber,
          Type: poNumberCustomField?.Type ?? "StringType",
          Name: poNumberCustomField?.Name ?? "PO",
        },
        {
          DefinitionId: invoiceNumberCustomField?.DefinitionId ?? "1",
          StringValue: values.invoiceNumber,
          Type: invoiceNumberCustomField?.Type ?? "StringType",
          Name: invoiceNumberCustomField?.Name ?? "Invoice no.",
        },
      ],
    };
  }

  const validateInvoice = () => {
    if (!validateLineItems()) return false;
    if (!validateRequiredFields()) return false;
  
    const postBody = createPostBody();
    console.log("Passed validation checks. Creating invoice...");
    handleCreateInvoice(postBody);
    return true;
  };

  const validateLineItems = () => {
    if (values.lineItems.length === 0) {
      makeErrorToast("Validation Error", "Please add at least one line item");
      setIsLoading(false);
      return false;
    }
    if (values.lineItems[0].Description === "") {
      makeErrorToast("Validation Error", "Please paste description from estimate");
      setIsLoading(false);
      return false;
    }
    return true;
  };

  const validateRequiredFields = () => {
    const requiredFields = [
      "customerId",
      "customerDisplayName",
      // "customerEmail",
      "purchaseOrderNumber",
      "invoiceNumber",
      "invoiceDate",
      "invoiceTerm",
      "invoiceDueDate",
    ];
  
    for (const field of requiredFields) {
      // @ts-ignore
      if (!values[field]) {
        makeErrorToast("Validation Error", `Please fill out all fields. Missing: ${field}`);
        console.log(values);
        setIsLoading(false);
        return false;
      }
    }
    return true;
  };

  const handleSendInvoice = async () => {
    // Find the invoice in qbResources using the dashboardDatabaseInvoiceId
    const quickbooksInvoice = qbResources.Invoice.find(
      (invoice) => invoice.Id === dashboardInvoiceData.quickbooks_id
    );
    if (!quickbooksInvoice) {
      makeErrorToast("Error sending invoice", "Could not find the invoice in Quickbooks. Try Refreshing the page or logging out.");
      return;
    }
    setQuickbooksInvoice(quickbooksInvoice);
    // Open dialogue to send invoice
    setIsSideDrawerOpen(true);
  }

  return (
    <div css={tw`p-6 m-6 rounded shadow`}>
      <section css={tw`w-full flex justify-between`}>
        <Heading size="md" mb={10}>
          Create Invoice for Estimate:
          <br /> {estimateName}
        </Heading>
        <div css={tw`flex flex-col justify-end`}>
          {isAccounting && !isLoggedIntoQuickbooks && (
            <LoginWithQuickbooks setIsAuthenticated={setIsLoggedIntoQuickbooks} />
          )}
          {isAccounting && isLoggedIntoQuickbooks && (
            <LogoutFromQuickbooks
              setIsAuthenticated={setIsLoggedIntoQuickbooks}
            />
          )}
          <Button onClick={() => { setIsSideDrawerOpen(true) }}>
            View Estimate PDF
          </Button>
        </div>
      </section>

      {isSideDrawerOpen && estimateFileKey && (
        <SideDrawer isOpen={isSideDrawerOpen} onClose={() => {setIsSideDrawerOpen(false)}}>
          <PdfViewer s3Key={estimateFileKey} />
        </SideDrawer>
      )}

      {isSideDrawerOpen && quickbooksInvoice && customer && (
        <SideDrawer isOpen={isSideDrawerOpen} onClose={() => {setIsSideDrawerOpen(false)}} width="90%">
          <SendInvoiceWithQuickbooks
            quickbooksCustomer={customer}
            quickbooksInvoice={quickbooksInvoice}
            dashboardDatabaseInvoiceId={dashboardInvoiceData.id}
            dashboardInvoiceNumber={invoiceNumberWithDash}
            setIsSideDrawerOpen={setIsSideDrawerOpen}
            updateOneClientInvoice={updateOneClientInvoice}
          />
        </SideDrawer>
      )}

      {isLoggedIntoQuickbooks && (
        <main>
          <div css={tw`w-full flex flex-col gap-y-3`}>
            <section css={tw`w-full grid grid-cols-4 gap-6`}>
              {/* Customer */}
              <div>
                <label htmlFor="customerId">
                  Customer
                  {!customerId && warning && (
                    <span style={{ color: "red" }}>
                      *<br />
                      No quickbooks customer was found for Company: "{companyName} - {brandName}". Please do the following<br/>
                      1) Log into quickbooks to Create the customer "{companyName} - {brandName}"{" "}
                      2) Refresh this page.
                      <button
                        css={tw`text-blue-500 underline`}
                        onClick={() => {
                          setWarning(false);
                        }}
                      >
                        Got It!
                      </button>
                    </span>
                  )}
                </label>
                {qbResources.Customer && (
                  <Select
                    id="customerId"
                    name="customerId"
                    value={values.customerId}
                    onChange={(e) => {
                      const selectedCustomerId = e.target.value;
                      const selectedCustomer = qbResources.Customer
                        ? qbResources.Customer.find(
                            (customer) => customer.Id === selectedCustomerId
                          )
                        : null;
                      if (selectedCustomer) {
                        const newCustomerTerm = qbResources.Term.find(
                          (term) => term.Id === selectedCustomer.SalesTermRef?.value
                        ) ?? defaultSalesTerm;
                        const newDueDate: string = new Date(
                          new Date().setDate(
                            new Date().getDate() + (newCustomerTerm?.DueDays ?? 30)
                          )
                        ).toLocaleDateString("en-CA", { timeZone: "UTC" });

                        setValues({
                          ...values,
                          customerId: selectedCustomer.Id,
                          customerDisplayName: selectedCustomer.DisplayName,
                          invoiceTerm: qbResources.Term.find(
                            (term) => term.Id === selectedCustomer.SalesTermRef?.value
                          ) ?? defaultSalesTerm,
                          invoiceDueDate: newDueDate
                        });
                      }
                    }}
                  >
                    {qbResources.Customer.map((customer, i) => (
                      <option key={i} value={customer.Id}>
                        {customer.DisplayName}
                      </option>
                    ))}
                  </Select>
                )}
              </div>

              {/* Invoice Date */}
              <div>
                <label htmlFor="invoiceDate">Invoice date</label>
                <div css={tw`border rounded-md px-4 py-2`}>
                  <PopoverInput
                    initialValue={values.invoiceDate}
                    displayText={values.invoiceDate}
                    type="date"
                    onSubmit={async (e: any, newValue: string) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        invoiceDate: newValue,
                      });
                    }}
                  />
                </div>
              </div>

              {/* Due Date */}
              <div>
                <label htmlFor="invoiceDueDate">Due date</label>
                <div css={tw`border rounded-md px-4 py-2`}>
                  <PopoverInput
                    initialValue={values.invoiceDueDate}
                    displayText={values.invoiceDueDate}
                    type="date"
                    onSubmit={async (e: any, newValue: string) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        invoiceDueDate: newValue,
                      });
                    }}
                  />
                </div>
              </div>

              {/* Term of Invoice - Due in Net 15, Net 30 days, etc */}
              <div>
                <label htmlFor="invoiceDueDate">Term</label>
                <Select
                  id="invoiceDueDate"
                  name="invoiceDueDate"
                  value={values.invoiceTerm?.Id}
                  onChange={(e) => {
                    const selectedTermId = e.target.value;
                    const selectedTerm = qbResources.Term.find(
                      (term) => term.Id === selectedTermId
                    );
                    if (selectedTerm) {
                      setValues({
                        ...values,
                        invoiceTerm: selectedTerm,
                        invoiceDueDate: new Date(
                          new Date().setDate(
                            new Date().getDate() + selectedTerm.DueDays
                          )
                        ).toLocaleDateString("en-CA", { timeZone: "UTC" }),
                      });
                    }
                  }}
                >
                  {qbResources.Term && qbResources.Term.map((term, i) => (
                    <option key={i} value={term.Id}>
                      {term.Name}
                    </option>
                  ))}
                </Select>
              </div>
                  
              {/* Purchase Order (PO) Number */}
              <div>
                <label htmlFor="purchaseOrderNumber">PO #</label>
                <Input
                  id="purchaseOrderNumber"
                  name="purchaseOrderNumber"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      purchaseOrderNumber: e.target.value,
                    });
                  }}
                  value={values.purchaseOrderNumber}
                />
              </div>

              {/* Invoice Number */}
              <div>
                <label htmlFor="invoiceNumber">Invoice #</label>
                <Input
                  id="invoiceNumber"
                  name="invoiceNumber"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      invoiceNumber: e.target.value,
                    });
                  }}
                  value={values.invoiceNumber}
                />
              </div>
            </section>

            {/* Line Items */}
            {qbResources.Item && qbResources.TaxRate && qbResources.TaxCode && (
              <LineItemsTable
                lineItems={values.lineItems}
                items={qbResources.Item}
                taxRates={qbResources.TaxRate}
                taxCodes={qbResources.TaxCode}
                onLineItemsChange={(updatedLineItems) => {
                  setValues({
                    ...values,
                    lineItems: updatedLineItems,
                  });
                }}
              />
            )}

            {/* Footer - Submit & Reset */}
            <footer css={tw`w-full flex justify-end`}>
              {quickbooksInvoice?.EmailStatus === "EmailSent" ? <>Invoice Sent</> : 
                <>
                  <Button
                    onClick={validateInvoice}
                    isLoading={isLoading}
                    loadingText="Creating invoice..."
                    colorScheme="blue"
                    css={tw`mt-4`}
                    disabled={isLoading || alreadyCreatedQBInvoice}
                  >
                    {alreadyCreatedQBInvoice ? "Already created invoice in Quickbooks" : "Create Invoice"}
                  </Button>
                  {alreadyCreatedQBInvoice && (
                    <Button
                      type="button"
                      onClick={handleSendInvoice}
                      colorScheme="blue"
                      css={tw`mt-4 ml-4`}
                    >
                      Send Invoice
                    </Button>
                  )}
                </>
              }
            </footer>
          </div>
        </main>
      )}
    </div>
  );
};