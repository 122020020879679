
import tw, { css } from "twin.macro";
import { VictoryChart, VictoryLine, VictoryAxis } from "victory";
import { abbreviateClientName } from "../../helpers/abbreviateClientName";
import { MinutesByCompany } from "../../helpers/combineMinutesByCompany";
import { Person } from "../../codegen/graphql-types";

interface ClientHoursLineGraphProps {
  person: Person;
  clients: MinutesByCompany[];
}

// Line graph that shows how many hours a person logged for each client over the last 3 months
export const HoursOverTimeLineGraph = ({ person, clients }: ClientHoursLineGraphProps) => {
  const year = new Date().getFullYear();

  return (
    <div css={tw`flex flex-col justify-center items-center`}>
      <h3 css={tw`my-6 font-bold`}>{person.first_name}'s Number of Hours</h3>
      {/* Legend for the line graph - client brand palette */}
      <figure css={[tw`grid grid-cols-2 gap-x-2`]}>
        {clients.map((client) => {
          const hex = client.clientColour;
          const name = abbreviateClientName(client.clientName, 20);
          return (
            <figcaption css={tw`flex items-center text-xs`} key={name}>
              <div
                css={[
                  tw`h-1 w-6`,
                  css`
                    background-color: ${hex};
                  `,
                ]}
              />
              &nbsp;{name}
            </figcaption>
          );
        })}
      </figure>
      <VictoryChart>
        {/* X-axis Configuration */}
        <VictoryAxis
          style={{
            axis: { stroke: "#756f6a" }, // Customize the axis line style
            grid: { stroke: "transparent" }, // No grid lines along x-axis
            ticks: { stroke: "grey", size: 5 },
            tickLabels: { fontSize: 15, padding: 5 },
          }}
        />

        {/* Y-axis Configuration */}
        <VictoryAxis
          dependentAxis // This configures it as the y-axis
          style={{
            axis: { stroke: "#756f6a" }, // Customize the axis line style
            grid: { stroke: "#E5E5E5" }, // Grid lines along y-axis
            ticks: { stroke: "grey", size: 5 },
            tickLabels: { fontSize: 15, padding: 5 },
          }}
        />
        
        {/* Line for each client */}
        {clients.map((client) => {
          const hex = client.clientColour;
          const minutesByDateRangeKeys = Object.keys(client.minutesByDateRange);
          return (
            <VictoryLine
              style={{ data: { stroke: hex, strokeWidth: 6 } }}
              data={minutesByDateRangeKeys.map((range) => {
                const rangeKey = range as keyof MinutesByCompany["minutesByDateRange"];

                return {
                  x: range,
                  y: client.minutesByDateRange[rangeKey] / 60,
                };
              })}
            />
          );
        })}
      </VictoryChart>
    </div>
  );
};
