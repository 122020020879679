import _ from "lodash";
// inferred type from the useProjectRows hook
import { ProjectRow } from "./useProjectRows";
import { nonBillableCompanyIds } from "../Time/constants";

export type ClientNetSalesRow = {
  clientId: string,
  clientName: string,
  sumNetSalesSplitAsOfCurrentMonthInPreviousYear: number,
  sumNetSalesSplitInPreviousYear: number,
  sumProfitabilityInPreviousYear: string | number,
  sumNetSalesSplitInFocusYear: number,
  sumInProgressOnHoldInFocusYear: number,
  sumProfitabilityInFocusYear: string | number,
  projects: ProjectRow[],
  projectsInFocusYear: ProjectRow[],
  projectsInPreviousYear: ProjectRow[]
};

// Takes sum of netSalesSplit for an array of projects
export const sumNetSalesSplit = (projects: ProjectRow[]) => {
  return _.sumBy(projects, (project: any) => project.netSalesSplit);
};
// Filtering callbacks for projects based on project_status
export const filterByCompletedCancelled = (project: ProjectRow) => {
  const status = project.project_status;
  return status === "Completed" || status === "Cancelled";
};
// filter callack fn for projects Profitability (Time tracking + Completed + Cancelled)
export const filterByProfitability = (project: ProjectRow) => {
  const status = project.project_status;
  return status === "Completed" || status === "Cancelled" || status === "Time tracking";
};
export const filterByInProgressOnHold = (project: ProjectRow) => {
  const status = project.project_status;
  return status === "In Progress" || status === "On Hold";
};

// function for summing Profitability of an array of projects
export const sumProfitability = (projects: ProjectRow[]) => {
  let sumNetSales = 0;
  let countNetSales = 0;

  let sumSubtotalByHours = 0;
  let countSubtotalByHours = 0;
  projects.forEach((project) => {
    if (project.netIncome) {
      sumNetSales += project.netIncome;
      countNetSales += 1;
    }

    const subtotalByTimeEntries = project.subtotalByTimeEntries;
    if (subtotalByTimeEntries) {
      sumSubtotalByHours += subtotalByTimeEntries;
      countSubtotalByHours += 1;
    }
  });

  const averageProfitability = sumNetSales / sumSubtotalByHours;

  // if not a number, return 0
  if (isNaN(averageProfitability)) {
    return 0;
  }

  return averageProfitability.toFixed(2);
};

// group projects by project.client.name (aka by Brand)
export const projectsGroupedByClientName = (projects: ProjectRow[]) => {
  return projects.reduce((acc: any, project: any) => {
    const clientName = project.client.name;
    if (!acc[clientName]) {
      acc[clientName] = [];
    }
    acc[clientName].push(project);
    return acc;
  }, {});
};

// filter projects by end date year
export const filterProjectsByEndDateYear = (projects: ProjectRow[], year: number) => {
  return projects.filter((project: any) => {
    const endDate = new Date(project.end_date);
    return endDate.getFullYear() === year;
  });
};

// Map, filter, and sort Brand/Client data for Brand Analysis table rows
export const processClientRows = (projectsByClientName: any, yearInFocus: number): ClientNetSalesRow[] => { 
  return Object.keys(projectsByClientName)
  .map((clientName: string) => {
    const projects = projectsByClientName[clientName];
    const clientId = projects[0].client.id;
    // client/brand projects for previous year + focus year
    const projectsInPreviousYear = filterProjectsByEndDateYear(projects, yearInFocus - 1);
    const projectsInFocusYear = filterProjectsByEndDateYear(projects, yearInFocus);
    // Sum of netSalesSplit (Completed + Cancelled) for projects in previous year + focus year
    const sumNetSalesSplitAsOfCurrentMonthInPreviousYear = sumNetSalesSplit(
      projectsInPreviousYear
        .filter(filterByCompletedCancelled)
        .filter((project: any) => {
          const endDate = new Date(project.end_date);
          const currentMonth = new Date().getMonth();
          return endDate.getMonth() <= currentMonth;
        })
    );
    const sumNetSalesSplitInPreviousYear = sumNetSalesSplit(projectsInPreviousYear.filter(filterByCompletedCancelled));
    const sumNetSalesSplitInFocusYear = sumNetSalesSplit(projectsInFocusYear.filter(filterByCompletedCancelled));
    // Sum of netSalesSplit for In Progress + On Hold projects in focus year (do not filter by project end_date)
    const sumInProgressOnHoldInFocusYear = sumNetSalesSplit(projects.filter(filterByInProgressOnHold));
    // Sum profibitability for projects in previous year 
    const sumProfitabilityInPreviousYear = sumProfitability(projectsInPreviousYear.filter(filterByProfitability));
    // Sum profibitability for projects in focus year
    const sumProfitabilityInFocusYear = sumProfitability(projectsInFocusYear.filter(filterByProfitability));

    if (clientName === "Abbott") {
      console.log(projectsInFocusYear);
      console.log(sumProfitabilityInFocusYear);
    }


    return {
      clientName,
      clientId,
      sumNetSalesSplitAsOfCurrentMonthInPreviousYear,
      sumNetSalesSplitInPreviousYear,
      sumProfitabilityInPreviousYear,
      sumNetSalesSplitInFocusYear,
      sumProfitabilityInFocusYear,
      sumInProgressOnHoldInFocusYear,
      projects,
      projectsInPreviousYear,
      projectsInFocusYear,
    };
  })
  .filter((client) => {
    // filter out clients with clientIds that are in nonBillableCompanyIds
    return !nonBillableCompanyIds.includes(client.clientId);
  })
  .filter((client) => {
    // filter out client rows if they have 0 in netSalesSplit for both years, and 0 sumInProgressOnHoldInFocusYear 
    return client.sumNetSalesSplitInPreviousYear > 0 || client.sumNetSalesSplitInFocusYear > 0 || client.sumInProgressOnHoldInFocusYear > 0;
  })
};