
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw, { css } from "twin.macro";
import React from "react";
import _ from "lodash";
import {
  Column,
  useFilters,
  useSortBy,
  useTable,
  usePagination,
} from "react-table";
import { filterTypes } from "../../helpers/filterTypes";
import { useLocalStorage } from "../../helpers/useLocalStorage";

const rowCountFormatter = new Intl.NumberFormat("en-US");

export const ProjectsTable = <
  ProjectRows extends Record<string, unknown>[],
  ColumnDefinitions extends Column<Record<string, unknown>>[]
>({
  columnDefinitions,
  projectRows,
  isSyncing,
}: {
  columnDefinitions: ColumnDefinitions;
  projectRows: ProjectRows;
  isSyncing: boolean;
}) => {
  const [currentPageSize, setCurrentPageSize] = useLocalStorage<number>(
    "projectsTablePageSize",
    50
  );
  const pageCount = Math.ceil(projectRows.length / currentPageSize);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: () => null,
      sortType: "alphanumeric",
    }),
    []
  );

  const initiallyHiddenColumns = React.useMemo(() => {
    const columnIds = columnDefinitions
      // @ts-expect-error
      .flatMap((x) => (x?.columns ?? []).flatMap((x) => x?.columns ?? []))
      .filter((x) => x.id.startsWith("individual:" || "initiallyHidden:"))
      .map((x) => x.id);
    return columnIds;
  }, [columnDefinitions]);

  const {
    // allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    rows,
    prepareRow,
    // @ts-ignore
    state: { pageIndex },
    // @ts-ignore
    gotoPage,
  } = useTable(
    {
      columns: columnDefinitions,
      data: projectRows ?? [],

      // initialState: { pageIndex: 0 }, // Pass our hoisted table state
      // manualPagination: true, // Tell the usePagination
      // // hook that we'll handle our own data fetching
      // // This means we'll also have to provide our own
      // // pageCount.
      // pageCount: pageCount,

      // @ts-expect-error
      defaultColumn,
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
      // autoResetGroupBy: false,
      autoResetSelectedRows: false,
      initialState: {
        hiddenColumns: initiallyHiddenColumns,
        // @ts-ignore
        pageSize: currentPageSize,

        // [
        //   `Brand Maven`,
        //   `Associate Brand Maven`,
        //   `Designer`,
        // ].flatMap((titleCategory) => [
        //   `initiallyHidden: Hours Logged ${titleCategory}`,
        //   `initiallyHidden: Subtotal ${titleCategory}`,
        // ]),
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const paginationControls = pageCount > 1 && (
    <>
      Page{" "}
      {_.range(pageCount).map((pageNumber) => (
        <button
          css={[
            tw`px-2`,
            pageNumber === pageIndex
              ? tw`font-bold`
              : tw`hover:underline hover:bg-gray-100`,
          ]}
          onClick={() => gotoPage(pageNumber)}
          disabled={pageNumber === pageIndex}
        >
          {pageNumber + 1}
        </button>
      ))}
    </>
  );
  return (
    <div>
      {/* <div>
        {allColumns.map((column) => (
          <div key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              {column.id}
            </label>
          </div>
        ))}
      </div> */}
      <div css={tw`mt-4 mb-2`}>
        {pageCount <= 1 ? (
          <>Showing {rowCountFormatter.format(rows.length)} rows, </>
        ) : (
          <>
            Showing {rowCountFormatter.format(pageIndex * currentPageSize + 1)}{" "}
            to{" "}
            {rowCountFormatter.format(
              Math.min(rows.length, (pageIndex + 1) * currentPageSize)
            )}{" "}
            of {rowCountFormatter.format(rows.length)} rows,{" "}
          </>
        )}

        <select
          id="pageSize"
          value={currentPageSize}
          css={tw`mr-2`}
          onChange={(e) => {
            setCurrentPageSize(Number(e.target.value));
          }}
        >
          {[50, 100, 500, 1000, 100_000].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {rowCountFormatter.format(pageSize)} rows per page
            </option>
          ))}
        </select>
        <br />
        {paginationControls}
      </div>
      
      <table
        {...getTableProps()}
        css={[
          tw`text-sm overflow-x-scroll`,
          isSyncing && tw`opacity-50`,
          css`
            .row-hover-visible {
              opacity: 0;
            }
            & tr:hover .row-hover-visible {
              opacity: 1;
            }

            .cell-hover-visible {
              opacity: 0;
            }
            & td:hover .cell-hover-visible {
              opacity: 1;
            }

            & .net-sales-expander, & .net-sales-collapser {
              position: absolute;
              top: 0;
              right: 0;
              padding: 6px;
              padding-bottom: 3px;
              padding-top: 3px;
              &:hover {
                background: #eee;
              }
            }
          `,
        ]}
      >
        <thead
          css={css`
            &,
            & > tr > th {
              background: aliceblue;
              border-right: solid 1px #ddd;
              border-left: solid 1px #ddd;
            }
            border-top: solid 1px #ddd;
          `}
        >
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerGroupColumnIndex) => {
                return (
                  <th
                    css={css`
                      padding: 0.5rem;
                      z-index: ${10 + headerGroupIndex};
                      /* backdrop-filter: blur(6px); */
                      ${
                        !column.placeholderOf &&
                        css`
                          border-bottom: solid 1px #ddd;
                        `
                      }
                      position: sticky;
                      ${
                        // @ts-expect-error
                        column.sticky ||
                        // @ts-expect-error
                        column.placeholderOf?.sticky ||
                        // @ts-expect-error
                        column.placeholderOf?.placeholderOf?.sticky
                          ? css`
                              left: ${// @ts-expect-error
                              column.stickyLeft ??
                              // @ts-expect-error
                              column.placeholderOf?.stickyLeft ??
                              // @ts-expect-error
                              column.placeholderOf?.placeholderOf?.stickyLeft ??
                              0}px;
                              z-index: ${10 + headerGroupIndex + 1};
                            `
                          : ""
                      }
                      top: ${[0, 50, 100][headerGroupIndex]}px;
                      border-left: solid 1px #ddd;
                      border-right: solid 1px #ddd;
                      box-sizing: border-box;

                      color: black;
                      font-weight: 500;
                    `}
                    // @ts-expect-error
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div style={{ display: "flex" }}>
                      {column.render("Header")}

                      {headerGroups.length === 1 ||
                        (headerGroups.length - 2 < headerGroupIndex && (
                          <span>
                            {
                              // @ts-expect-error
                              column.isSorted
                                ? // @ts-expect-error
                                  column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : " ↕️"
                            }
                          </span>
                        ))}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
          <tr key="aggregateHeaderRow">
            {headerGroups.slice(-1)[0].headers.map((column) => {
              return (
                <th
                  key={column.id}
                  css={css`
                    background: aliceblue;
                    padding: 0.5rem;
                    border-left: solid 1px #ddd;
                    border-right: solid 1px #ddd;
                    border-bottom: solid 1px #ddd;
                    box-sizing: border-box;

                    color: black;
                    font-weight: 500;

                    position: sticky;
                    z-index: 20;
                    top: 170px;
                    ${// @ts-expect-error
                    column.sticky
                      ? css`
                          left: ${((column as unknown) as any).stickyLeft ??
                          0}px;
                          z-index: 21;
                        `
                      : ""}
                  `}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {
                      // @ts-ignore
                      column.Header2 && column.render("Header2")
                    }
                  </div>
                  {
                    // @ts-expect-error
                    column.canFilter && column.render("Filter")
                  }
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody
          {...getTableBodyProps()}
          css={css`
            & > tr {
              content-visibility: auto;
              contain-intrinsic-size: 1000px;
              & > td {
                border: solid 1px #ddd;
                padding: 0.5rem;
              }
            }
          `}
        >
          {page
            // rows
            // .slice(
            //   currentPageNumber * currentPageSize,
            //   (currentPageNumber + 1) * currentPageSize
            // )
            .map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    const cellProps = cell.getCellProps();
                    return (
                      <td
                        {...cellProps}
                        style={{
                          textAlign:
                            typeof cell.value === "number" ||
                            (typeof cell.value === "string" &&
                              cell.value.startsWith("$"))
                              ? "right"
                              : "left",
                          ...(((cell.column as unknown) as any).sticky
                            ? {
                                position: "sticky",
                                left:
                                  ((cell.column as unknown) as any)
                                    .stickyLeft ?? 0,
                                backgroundColor: "white",
                                zIndex: 1,
                              }
                            : null),
                          ...(((cell.column as unknown) as any).getCellStyles?.(cell) ?? {}),
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
      {paginationControls}
    </div>
  );
};
