import * as pathToRegexp from "path-to-regexp";

class AppRoute <RouteParams extends Record<string, string>> {
  public definition: string;
  public getInstance: pathToRegexp.PathFunction<RouteParams>;

  constructor(routeDefinition: string) {
    this.definition = routeDefinition;
    this.getInstance = pathToRegexp.compile(routeDefinition);
  }
}

// Public Pages
export const eula = new AppRoute("/eula");
export const privacy = new AppRoute("/privacy");

// Auth
export const handleLogin = new AppRoute("/handle_login");
export const loginPage = new AppRoute("/login");
export const postLogout = new AppRoute("/");

// Financials
export const grossProfitReport = new AppRoute("/financials/performance");
export const estimatesPage = new AppRoute("/financials/estimates");
export const clientInvoicesPage = new AppRoute("/financials/invoices");
export const createInvoice = new AppRoute("/financials/invoices/new");

// Projects
export const dashboardPage = new AppRoute("/projects/dashboard");
export const newExpense = new AppRoute("/projects/expenses");

// Time
export const timeEmployee = new AppRoute("/time/employee");
export const timePod = new AppRoute("/time/pod");

// Admin
export const adminTime = new AppRoute("/admin/time");
export const adminForecasting = new AppRoute("/admin/forecasting");
export const adminTimeByMonth = new AppRoute("/admin/time-by-month");

// Quickbooks / Accounting
export const quickbooksLogin = new AppRoute("/quickbooks/login");
export const quickbooksDashboard = new AppRoute("/quickbooks/dashboard");